import { computed } from 'mobx'
import Question from './index'
import { parseLegends, parseRates } from './utils'

export default class Rating extends Question {
  @computed get dontKnowText() {
    return this.options.raw.dont_know_text
  }

  @computed get isCondensed() {
    return this.options.isCondensed
  }

  @computed get choices() {
    let choices = this.rate_values.map((choice, choiceIndex) => ({
      id: `${choice.text}-${choiceIndex}`,
      value: choice.value,
      text: choice.text
    }))

    if (this.splitted) {
      choices = parseRates(choices, this.splitted)
    }

    return choices
  }

  @computed get legends() {
    if (!this.isCondensed) return null
    return this.rate_values.filter(rate => rate.legend).map(({ legend }) => legend)
  }

  @computed get rate_values() {
    return parseLegends(this.options.rateValues, this.isCondensed)
  }

  @computed get splitted() {
    return this.options.raw.columns_by_row
  }

  @computed get dontKnowButton() {
    return this.dontKnowActive ? this.dontKnowOption : null
  }
}
