import React from 'react'
import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function Notification() {
  return (
    <ToastContainer
      position='top-right'
      transition={Slide}
      autoClose={4000}
      theme='colored'
      toastClassName='probando-clase'
      newestOnTop
      closeOnClick
      pauseOnHover
    />
  )
}
