import React, { Component } from 'react'
import { Image as Img } from 'semantic-ui-react'

class Image extends Component {
  render() {
    const { src, style, centered = true } = this.props
    return <Img src={src} style={style} centered={centered} />
  }
}

Image.displayName = 'Image'

export default Image
