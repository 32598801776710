import { action, computed, makeObservable, observable } from 'mobx'
import kebabCase from 'lodash/kebabCase'
import { uuid } from 'uuidv4'
import stores from '@stores'

export default class Row {
  @observable id = ''
  @observable value = ''
  @observable text = ''
  @observable can_edit = true
  @observable is_saved = false
  @observable focus = false

  constructor(multipleRatingStore, props = {}) {
    makeObservable(this)
    this.multipleRatingStore = multipleRatingStore
    this.id = Math.random()
    this.value = props.value || this.value
    this.setText(props.text)
    this.can_edit = 'can_edit' in props ? props.can_edit : this.can_edit
    this.focus = props.focus || this.focus
    this.is_saved = props.is_saved || this.is_saved
  }

  @action setText(text = '') {
    this.text = text ? text.trim() : text
  }

  @action setValue(value) {
    this.value = value
  }

  @action setFocus(focus) {
    this.focus = focus
  }

  @action setSaved(value) {
    this.is_saved = value
  }

  @action edit({ text }) {
    this.setText(text)

    if (!this.value) {
      const textParsed = kebabCase(this.text)
      const orgParsed = stores.appState.organization.id.slice(0, 4)
      const uuidv4 = uuid().slice(0, 6)
      this.setValue(`${textParsed}-${orgParsed}-${uuidv4}`)
    }

    this.setFocus(false)
    this.setSaved(false)
  }

  @action async saveCompetency() {
    const { competencies } = this.multipleRatingStore
    const { codename } = await competencies.add({ text: this.text })
    this.setValue(codename)
    this.setSaved(true)
  }

  @computed get duplicated() {
    const { items } = this.multipleRatingStore

    for (let i = 0; i < items.length; i += 1) {
      const item = items[i]
      if (item.id === this.id) return false
      if (item.text === this.text) return true
    }

    return false
  }

  @computed get raw() {
    return {
      text: this.text,
      value: this.value,
      can_edit: this.can_edit,
      is_saved: this.is_saved
    }
  }

  @computed get canShowSave() {
    return this.multipleRatingStore.showCompetencyLibrary && this.can_edit
  }

  @computed get saveIcon() {
    return this.is_saved ? 'check' : 'save'
  }

  @computed get saveTooltip() {
    return this.is_saved
      ? 'Added to Organization Library'
      : 'Add to Organization Library'
  }

  @computed get saveDisabled() {
    return this.is_saved
  }
}
