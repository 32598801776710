import { Component } from 'react'

class Main extends Component {
  render() {
    return this.props.children
  }
}

Main.displayName = 'Main'

export default Main
