import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { BASE_URL_API_OLD, CUSTOM_MODULES_BASE_URL } from './constants'

const URLS = {
  REFRESH_TOKEN: `${BASE_URL_API_OLD}/auth/token/refresh/`
}

class AuthClass {
  error = null

  cleanStorage() {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
  }

  signOut() {
    this.cleanStorage()
    this.error = null
    location.replace(CUSTOM_MODULES_BASE_URL)
  }

  async refreshToken() {
    if (this.isRefreshTokenExpired()) {
      this.signOut()
    } else {
      const refresh = this.getRefreshToken()
      return axios
        .post(URLS.REFRESH_TOKEN, { refresh })
        .then(response => {
          localStorage.setItem('access_token', response.data.access)
          this.error = null
        })
        .catch(error => {
          this.error = error
        })
    }
  }

  getExpirationTime() {
    const decodedAccessToken = this.decodedAccessToken()
    return decodedAccessToken.exp
  }

  isTokenExpirated() {
    return this.getExpirationTime() <= Date.now() / 1000
  }

  getExpirationTimeRefreshToken() {
    const decodedRefreshToken = this.decodedRefreshToken()
    return decodedRefreshToken.exp
  }

  isRefreshTokenExpired() {
    const expirationDate = this.getExpirationTimeRefreshToken()
    return expirationDate < Date.now() / 1000
  }

  async isAuthenticated() {
    const access_token = this.getAccessToken()
    const refresh_token = this.getRefreshToken()
    return !!access_token && !!refresh_token
  }

  async copyStorage() {
    if (navigator.clipboard) {
      return navigator.clipboard
        .readText()
        .then(res => {
          const storage = JSON.parse(res)

          if (storage.access_token && storage.refresh_token) {
            this.setAccessToken(storage.access_token)
            this.setRefreshToken(storage.refresh_token)
            return true
          }

          return false
        })
        .catch(() => false)
    }

    return false
  }

  // ----- GETTERS -----

  decodedAccessToken() {
    const accessToken = this.getAccessToken()
    return jwt_decode(accessToken)
  }

  decodedRefreshToken() {
    const refreshToken = this.getRefreshToken()
    return jwt_decode(refreshToken)
  }

  getAccessToken() {
    return localStorage.getItem('access_token')
  }

  getRefreshToken() {
    return localStorage.getItem('refresh_token')
  }

  setAccessToken(value) {
    localStorage.setItem('access_token', value || '')
  }

  setRefreshToken(value) {
    localStorage.setItem('refresh_token', value || '')
  }

  getAuthorizationToken() {
    const token = this.getAccessToken()
    return token ? `Bearer ${token}` : null
  }
}

// Create Instance
let instance = null

if (!instance) {
  instance = new AuthClass()
}

const Auth = instance

export default Auth
