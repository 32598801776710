import { action, computed, makeObservable, override, reaction } from 'mobx'
import MultipleSelection from '../../models/Question/MultipleSelection'
import Question from './index'

export default class SurveyMultipleSelection extends Question(MultipleSelection) {
  constructor(previewStore, props = {}) {
    super(previewStore, props)
    makeObservable(this)

    reaction(
      () => this.active,
      () => this.removeAnswersIfSelected()
    )
  }

  @override get choices() {
    let choices = super.choices.map(item => {
      if (item.value === 'other') {
        return {
          ...item,
          active: this.isOtherSelected,
          disabled: !this.isOtherSelected && !this.allowToPickMore
        }
      }

      if (item.value === 'none') {
        return {
          ...item,
          active: this.isNoneSelected,
          disabled: this.answer && this.answer.length > 0
        }
      }

      const active =
        this.answer && this.answer.some(selected => selected === item.value)

      return {
        ...item,
        active,
        disabled: !this.allowToPickMore && !active
      }
    })

    if (this.hasRemovedChoices) {
      choices = choices.filter(
        choice => !this.answersToRemove.includes(choice.value)
      )
    }
    return choices
  }

  @computed get isOtherOptionSelectedEmpty() {
    return this.answer && this.answer.some(selected => selected === '')
  }

  @override get hasValidAnswer() {
    if (this.isNoneSelected) return true
    if (this.isOtherSelected && this.isOtherOptionSelectedEmpty) return false
    if (!this.answer) return false

    const minValid = this.answer.length >= this.minCount
    const maxValid = !this.maxCount || this.answer.length <= this.maxCount
    return minValid && maxValid
  }

  @computed get isNoneSelected() {
    return this.answer && this.answer.length === 0
  }

  @computed get isOtherSelected() {
    return (
      !!this.answer &&
      !this.answer.every(selected =>
        this.items.some(({ value }) => value === selected)
      )
    )
  }

  @computed get hasOther() {
    return this.otherActive
  }

  @computed get showForm() {
    return this.isOtherSelected
  }

  @computed get allowToPickMore() {
    return !this.answer || !this.maxCount || this.answer.length < this.maxCount
  }

  @computed get answersToRemove() {
    const question = this.previewStore.stepsManager.steps.find(
      step => step.codename === this.removeChoicesSelectedIn
    )

    return question && question.answer ? question.answer : []
  }

  @computed get noteAnswerValue() {
    return this.note
  }

  saveAnswer = selected => {
    let answer = this.answer ? [...this.answer] : []
    if (selected === 'none') {
      answer = this.isNoneSelected ? null : []
    } else if (selected === 'other') {
      if (this.isOtherSelected) {
        answer = this.answer.filter(selected =>
          this.items.some(({ value }) => selected === value)
        )
        if (answer.length === 0) answer = null
      } else {
        answer.push('')
      }
    } else {
      const pos = answer.indexOf(selected)
      if (pos > -1) {
        answer.splice(pos, 1)
        if (answer.length === 0) answer = null
      } else {
        answer.push(selected)
      }
    }

    this.setAnswer(answer)
  }

  @action wasNotePartiallyAnswered(answer) {
    this.note = answer
  }

  @action removeAnswersIfSelected() {
    if (this.answer && this.hasRemovedChoices && this.active) {
      const newAnswers = this.answer.filter(a => !this.answersToRemove.includes(a))
      if (newAnswers.length === 0) {
        this.answer = undefined
      } else {
        this.answer = newAnswers
      }
    }
  }

  @action updateFormValue = value => {
    this.answer = this.answer.map(selected => {
      if (this.items.some(({ value }) => value === selected)) {
        return selected
      }

      return value
    })
  }

  @computed get formValue() {
    return this.answer
      ? this.answer.find(
          selected => !this.items.some(({ value }) => value === selected)
        )
      : ''
  }

  @computed get formPlaceholder() {
    return this.otherPlaceholder
  }

  @override get answerParsed() {
    if (this.isNoneSelected) return [this.noneOption.text]
    if (!this.answer) return []
    return this.answer
      .map(answer => {
        const option = this.choices.find(item => item.value === answer)
        return option ? option.text : answer
      })
      .join(', ')
  }
}
