import { action, observable, computed, makeObservable, toJS } from 'mobx'
import { apiOld } from '@api'
import { MODULES } from '@constants'
import StepsManager from '../models/StepsManager'
import Survey from '../models/Survey'
import { QUESTIONS_MODELS } from './Question/constants'
import Module from '../models/Module'
import Filters from './Filters'
import stores from '..'
import i18n from '../../i18n'

class SurveyState {
  @observable stepsManager = new StepsManager()
  @observable customization = {}
  @observable tokens = {}
  @observable context = {}
  @observable perfect_scoring_is_accepted = false
  @observable survey = new Survey()
  @observable version = 0
  @observable filters = new Filters()
  @observable error = ''
  @observable language_code = 'en'
  @observable changing_language = false
  @observable loading = false
  @observable noteAnswers = {}
  @observable noteAnswersCopy = {}
  @observable org_id = ''
  @observable info = {}
  @observable team_id = ''

  constructor(rootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @action setFilters(filters) {
    this.filters.set(filters)
  }

  @action initFilters(props) {
    this.filters.initFilters(props)
  }

  @computed get isCompleted() {
    return this.nextStepIndex === this.steps.length
  }

  @action setSteps = steps => {
    this.stepsManager.setSteps(steps)
  }

  @computed get steps() {
    return this.stepsManager.steps
  }

  @computed get modules() {
    return this.survey.modules
  }

  @computed get questionCount() {
    return this.modules.reduce((acc, module) => acc + module.questions.length, 0)
  }

  @computed get currentStep() {
    return this.stepsManager.currentStep
  }

  @computed get currentQuestion() {
    return this.stepsManager.currentStep
  }

  @computed get currentStepIndex() {
    return this.stepsManager.current_index
  }

  @computed get previousStepIndex() {
    return this.stepsManager.previousStepIndex
  }

  @computed get nextStepIndex() {
    return this.stepsManager.nextStepIndex
  }

  @computed get currentStepName() {
    return this.currentStep.title
  }

  @computed get codename() {
    return this.filters.codename
  }

  @computed get audience() {
    return this.filters.audience
  }

  @computed get relationship() {
    return this.filters.relationship
  }

  @computed get showAll() {
    return this.filters.showAll
  }

  @computed get showExtraInfo() {
    return this.filters.showExtraInfo
  }

  @computed get menuAvailable() {
    return this.filters.menuAvailable
  }

  @computed get excludeModules() {
    return this.filters.exclude_modules
  }

  @computed get changingLanguage() {
    return this.changing_language
  }

  @action async setLanguage(code) {
    this.language_code = code || 'en'
    await i18n.changeLanguage(this.language_code)
  }

  @action setFilter = (key, value) => {
    this.filters.set({ [key]: value })
  }

  @action setCodenameFilter = value => this.filters.setCodename(value)

  @action setCurrentStep = index => {
    this.stepsManager.setCurrentIndex(index)
  }

  @action previousQuestion = () => {
    if (this.previousStepIndex >= 0) {
      this.currentStep.active = false
      this.stepsManager.previousStep()
      if (this.currentStep.isQuestion) {
        this.currentStep.active = true
        if (!this.currentStep.matchingRules(this.answers, this.tokens)) {
          this.previousQuestion()
        }
      }
    }
  }

  @computed get lastAnswered() {
    return (
      this.steps.length -
      1 -
      this.steps
        .slice()
        .reverse()
        .findIndex(step => step.wasAnswered)
    )
  }

  @computed get answers() {
    const res = {}

    this.steps.forEach(step => {
      if (step.codename) {
        res[step.codename] = step.answer
      }
    })

    return res
  }

  hasChangedQuestionStatus(question) {
    if (!question.matchingRules) return false

    const isEnabled = question.matchingRules(this.answers, this.tokens)
    const wasEnabled = question.matchingRules(this.answersCopy, this.tokens)

    return isEnabled !== wasEnabled
  }

  @action savePartialNoteAnswer(answer) {
    const mapped = this.currentStep
    mapped.wasNotePartiallyAnswered(answer)
    if (mapped.isQuestion && mapped.hasNotes) {
      this.noteAnswersCopy = toJS(this.noteAnswers)
      this.noteAnswers[mapped.codename] = answer
    }
  }

  @action continue = () => {
    if (
      this.currentStep.isQuestion &&
      this.currentStep.dirty &&
      this.lastAnswered !== this.currentStepIndex
    ) {
      // If is not saving the answer for the first time
      // we should review following answers' rules
      for (let i = this.currentStepIndex + 1; i < this.steps.length; i++) {
        const question = this.steps[i]
        if (this.hasChangedQuestionStatus(question)) question.reset()
      }
    }

    if (this.currentStep.isQuestion) {
      this.currentStep.setAnswered(true)
    }

    this.nextQuestion()
  }

  @action nextQuestion = () => {
    this.currentStep.active = false
    if (this.nextStepIndex < this.steps.length) {
      this.stepsManager.nextStep()
      if (this.currentStep.isQuestion) {
        this.currentStep.active = true
        if (!this.currentStep.matchingRules(this.answers, this.tokens)) {
          this.currentStep.skip()
          this.nextQuestion()
        }
      }
    }
  }

  @action saveAnswers() {}

  @computed get lastQuestionAnswered() {
    let position = -1

    for (let i = 0; i < this.steps.length; i++) {
      const current = this.steps[i]

      if (current.isQuestion) {
        if (
          !current.wasAnswered &&
          current.isQuestionEnabled(this.answers, this.tokens)
        ) {
          break
        } else {
          position = i
        }
      }
    }
    return position
  }

  @computed get progress() {
    const lastQuestionAnswered = this.lastQuestionAnswered
    let questions = 0
    let answers = 0

    this.stepsManager.steps.forEach((step, index) => {
      if (step.isQuestion) {
        questions++
        if (
          step.wasAnswered ||
          (index <= lastQuestionAnswered &&
            !step.isQuestionEnabled(this.answers, this.tokens))
        ) {
          answers++
        }
      }
    })

    return Math.round((answers * 100) / questions)
  }

  @action setCustomization = values => {
    this.customization = values
  }

  @action adjustColor(rgb, amount) {
    const color = rgb.split(',')
    const r = Math.max(Math.min(parseInt(color[0]) + amount, 255), 0)
    const g = Math.max(Math.min(parseInt(color[1]) + amount, 255), 0)
    const b = Math.max(Math.min(parseInt(color[2]) + amount, 255), 0)
    return `${r},${g},${b}`
  }

  @computed get brandColorDark() {
    return this.adjustColor(this.customization.brand_color, -30)
  }

  @computed get hasBrandColor() {
    const { brand_color } = this.customization
    return this.customization && !!brand_color && brand_color !== '41, 136, 226'
  }

  @computed get hasBrandLogo() {
    return this.customization && !!this.customization.brand_logo
  }

  @computed get brandLogo() {
    return this.customization.brand_logo
  }

  @computed get languagesActive() {
    return stores.appState.languages.list
  }

  @action setTokens(tokens = {}) {
    this.tokens = tokens || {}
  }

  renderTokens = (text = '') => {
    const regex = /\{(.*?)\}/g
    if (text && typeof text === 'string') {
      while (text.match(regex)) {
        text = text.replace(regex, (_, val) => this.tokens[val])
      }

      return text
    }
    return text
  }

  @computed get isLastQuestion() {
    return !this.hasNextQuestion
  }

  @computed get isFirstStep() {
    return this.currentStepIndex === 0 && !this.currentStep.isQuestion
  }

  @computed get isLastStep() {
    return this.currentStepIndex === this.steps.length - 1
  }

  @computed get hasNextQuestion() {
    const next = this.nextStepIndex
    const length = this.steps.length

    if (next < length) {
      for (let i = this.nextStepIndex; i < this.steps.length; i++) {
        const step = this.steps[i]
        if (step.isQuestion && step.matchingRules(this.answers, this.tokens)) {
          return true
        }
      }
    }

    return false
  }

  @computed get continueButton() {
    return this.isFirstStep ? 'Start' : 'Next'
  }

  @action initSurvey = data => {
    const survey = data
    this.survey.setModules(
      survey.map(module => {
        const questions = module.questions.map(
          question =>
            new QUESTIONS_MODELS[
              question.ui_representation || question.question_type
            ](this, {
              ...question,
              flags: { is_required: question.is_required }
            })
        )

        const moduleInstance = new Module(module)
        moduleInstance.setQuestions(questions)

        return moduleInstance
      })
    )
  }

  @action setAnswers = (answers = {}) => {
    this.survey.modules.forEach(module =>
      module.questions.forEach(question => {
        question.answer = answers[question.codename]
        question.answered = question.answer !== undefined
      })
    )
  }

  @action acceptPerfectScoring = () => {
    this.perfect_scoring_is_accepted = true
  }

  @computed get perfectScoringIsAccepted() {
    return this.isVoiceModule || this.perfect_scoring_is_accepted
  }

  @computed get perfectScoreWarning() {
    return this.stepsManager.currentStep.hasPerfectScore
  }

  @computed get showPerfectScoreWarning() {
    return !this.perfectScoringIsAccepted && this.perfectScoreWarning
  }

  @action getCustomization = async () =>
    apiOld
      .get('/survey-editor/customizations/', {
        params: {
          survey_id: this.id,
          org_id: this.org_id,
          module: this.entityCode,
          team_id: this.teamId
        }
      })
      .then(response => this.setCustomization(response.data))
      .catch(error => {
        this.error = error
      })

  @computed get languagesOptions() {
    return stores.appState.languages.list
  }

  @computed get entityCode() {
    return this.info.entity || MODULES.CQ360
  }

  @computed get isVoiceModule() {
    return this.entityCode === MODULES.VOICE
  }

  @computed get teamId() {
    return this.info.team_id || this.team_id
  }

  @action setLanguages = languages => {
    stores.appState.setLanguages(languages)
  }
}

export default SurveyState
