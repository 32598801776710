import React from 'react'
import { action, computed, makeObservable, observable } from 'mobx'
import { api } from '@api'
import QuestionType from './QuestionType'
import { ICONS } from './constants'
import stores from '@stores'

export default class QuestionsTypes {
  @observable list = []
  @observable loading = true

  constructor(list = []) {
    makeObservable(this)
    this.list = list.map(question => new QuestionType(question))
  }

  @action set(list = []) {
    this.list = list.map(question => new QuestionType(question))
  }

  @action async get(params = {}) {
    return api
      .get('/question-types/', { params })
      .then(response => {
        this.set(response.data)
        this.loading = false
      })
      .catch(error => {
        this.error = error
        this.loading = false
      })
  }

  @computed get options() {
    return this.optionsList.map(({ name, ui_representation, codename, icon }) => ({
      text: name,
      value: codename,
      icon: (
        <img src={ICONS[icon || ui_representation]} className='question-type-icon' />
      ),
      ui_representation: ui_representation
    }))
  }

  @computed get selectable() {
    return this.list.filter(({ is_selectable }) => is_selectable)
  }

  @computed get optionsList() {
    if (!this.list.length) return []

    const { module } = stores
    const { currentQuestion } = module
    const { type, canEditType } = currentQuestion
    const questionType = this.list.find(({ codename }) => codename === type)

    if (canEditType && questionType.is_selectable) return this.selectable

    if (canEditType) return [questionType, ...this.selectable]

    return [questionType]
  }

  @computed get isEmpty() {
    return this.list.length === 0
  }
}
