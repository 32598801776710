import { action, computed, observable, makeObservable } from 'mobx'
import stores from '@stores'
import i18n from '../../../i18n'
import Options from './Options'

const DEFAULT_REPORT_VISUALIZATION = 'plain'

export default class Question {
  @observable codename = ''
  @observable description = ''
  @observable id = ''
  @observable title = 'Untitled'
  @observable order = 0
  @observable original_question = {}
  @observable type = ''
  @observable question_type_display = ''
  @observable ui_representation = ''
  @observable version = ''
  @observable status = null
  @observable report_visualization = null
  @observable relationships = []
  @observable question_library = null
  @observable to_question_library = null
  @observable desktop_options = new Options()
  @observable mobile_options = new Options()
  @observable flags = {
    is_required: false,
    disabled_for_audience: false,
    is_original: false
  }

  constructor(props = {}) {
    makeObservable(this)
    this.codename = props.codename || ''
    this.description = props.description || ''
    this.flags = props.flags || {
      is_required: true,
      disabled_for_audience: false,
      is_original: false
    }
    this.id = props.id || ''
    this.title = props.title || 'Untitled'
    this.order = props.order || 0
    this.original_question = props.original_question || {}
    this.type = props.question_type || ''
    this.question_type_display = props.question_type_display || ''
    this.ui_representation = props.ui_representation || ''
    this.version = props.version || ''
    this.relationships = props.relationships || []
    this.report_visualization =
      props.report_visualization || this.defaultReportVisualization
    this.status = props.status || null
    this.question_library = props.question_library || this.question_library
    this.to_question_library = props.to_question_library || this.to_question_library
    this.desktop_options.set(props.options)
    this.mobile_options.set(props.mobile_options || props.options)
  }

  @action set(props = {}) {
    this.codename = props.codename || ''
    this.description = props.description || this.description
    this.title = props.title || this.title || 'Untitled'
    this.flags = props.flags || {
      is_required: true,
      disabled_for_audience: false,
      is_original: false
    }
    this.id = props.id || ''
    this.order = props.order || 0
    this.original_question = props.original_question || {}
    this.type = props.question_type || ''
    this.question_type_display = props.question_type_display || ''
    this.ui_representation = props.ui_representation || ''
    this.version = props.version || ''
    this.relationships = props.relationships || []
    this.report_visualization =
      props.report_visualization || this.defaultReportVisualization
    this.status = props.status || null
    this.question_library = props.question_library || this.question_library
    this.to_question_library = props.to_question_library || this.to_question_library
    this.desktop_options.set(props.options)
    this.mobile_options.set(props.mobile_options || props.options)
  }

  @computed get isDisabledForAudience() {
    return this.flags.disabled_for_audience
  }

  @computed get isRequired() {
    return this.flags.is_required
  }

  @computed get isOptional() {
    return !this.isRequired
  }

  @computed get isQuestion() {
    return true
  }

  @computed get otherOption() {
    return { id: 'other', text: i18n.t('Other'), value: 'other' }
  }

  @computed get noneOption() {
    return { id: 'none', text: i18n.t('None'), value: 'none' }
  }

  @computed get dontKnowOption() {
    return {
      id: 'dont-know',
      text: i18n.t(this.dontKnowText || "I don't know"),
      value: 0,
      inverted: stores.module.isResponsive
    }
  }

  @computed get minCount() {
    return this.options.min_count
  }

  @computed get maxCount() {
    return this.options.max_count
  }

  @computed get renderType() {
    return this.ui_representation || this.question_type
  }

  @computed get typeName() {
    return this.question_type_display
  }

  @computed get layout() {
    return this.options.layout || 'inline'
  }

  @computed get dontKnowActive() {
    return this.options.dontKnowActive
  }

  @computed get noneActive() {
    return this.options.noneActive
  }

  @computed get otherActive() {
    return this.options.otherActive
  }

  @computed get showInline() {
    return this.layout === 'inline'
  }

  @computed get options() {
    return stores.module.isResponsive ? this.mobile_options : this.desktop_options
  }

  @computed get defaultReportVisualization() {
    const { report_visualization } = stores.appState
    const options = report_visualization.filter(r =>
      r.available_for.includes(this.type)
    )
    const defaultOption = options.find(
      ({ codename }) => codename === DEFAULT_REPORT_VISUALIZATION
    )

    return defaultOption?.codename || options[0]?.codename
  }
}
