import React, { Component } from 'react'
import { Header } from 'semantic-ui-react'
import Title from '../Pieces/Title'
import Content from '../Pieces/Content'
import Image from '../Pieces/Image'
import Icon from '../Pieces/Icon'

export default class View extends Component {
  static Title = Title
  static Content = Content
  static Image = Image
  static Icon = Icon

  resolveChildren = childName =>
    React.Children.map(this.props.children, child => {
      if (child.type.displayName === childName) {
        return React.cloneElement(child)
      }
    })

  render() {
    return (
      <div className='flex vertical full height centered'>
        {this.resolveChildren('Image')}
        {this.resolveChildren('Icon')}
        <Header as='h1' textAlign='center'>
          <Header.Content>
            {this.resolveChildren('Title')}
            <Header.Subheader>{this.resolveChildren('Content')}</Header.Subheader>
          </Header.Content>
        </Header>
      </div>
    )
  }
}
