import { action, computed, makeObservable, observable, override } from 'mobx'
import stores from '@stores'
import MultipleRating from '@stores/models/Question/MultipleRating'
import Competencies from './Competencies'
import Question from '../index'
import Row from './Row'
import { parseRate } from '../utils'

export default class EditorMultipleRating extends Question(MultipleRating) {
  @observable scale_selected = null
  @observable competencies = new Competencies(this)

  constructor(moduleStore, props = {}) {
    super(moduleStore, props)
    makeObservable(this)
    this.init(props)
  }

  @computed get defaultItem() {
    return { is_saved: false, can_edit: true, focus: true }
  }

  @override get optionsParams() {
    return {
      ...super.optionsParams,
      rows: this.desktopItems.map(item => item.raw)
    }
  }

  @override get params() {
    return {
      ...super.params,
      rating_scale_codename: this.scale_selected
    }
  }

  @computed get canRemove() {
    return this.items.length > 1
  }

  @override get rateValues() {
    if (this.splitted) return super.rateValues

    const list = super.rateValues.map(rateValue => {
      if (rateValue.id === this.dontKnowOption.id)
        return { ...rateValue, editable: false, inactive: true }

      const { prefix, text } = parseRate(rateValue.text)

      return {
        ...rateValue,
        editable: this.buttonsEditable,
        inactive: !this.buttonsEditable,
        text,
        prefix
      }
    })

    return list
  }

  @action init(props) {
    this.setScaleSelected(props.rating_scale_codename)
    this.setColumns(this.desktop_options.columns)
    this.setItems(this.desktop_options.rows.map(item => new Row(this, item)))
  }

  @override set(props) {
    super.set(props)
    this.setColumns(this.desktop_options.columns)
    this.setItems(this.desktop_options.rows.map(item => new Row(this, item)))
  }

  @action setColumns(columns = []) {
    if (columns.length === 0) {
      const { scales_wording } = stores.appState
      if (this.showRatingScale) {
        const { codename, scale } = scales_wording.defaultScale
        this.setScaleSelected(codename)
        this.desktop_options.setColumns(scale)
      } else {
        this.desktop_options.setColumns(scales_wording.default_options)
      }
    } else {
      this.desktop_options.setColumns(columns)
    }
  }

  @action updateButton({ id, text }) {
    const column = this.columns.find(column => column.value === id)
    column.text = text
  }

  @action changeScaleWording({ codename, scale }) {
    this.setScaleSelected(codename)
    this.setColumns(scale)
  }

  @action setScaleSelected(codename) {
    const { scales_wording } = stores.appState
    const { defaultScale } = scales_wording
    this.scale_selected = codename || defaultScale.codename
  }

  @override addItem(item) {
    this.addingItem = true
    this.items.push(new Row(this, item || this.defaultItem))
    this.autoScroll++
  }

  @override removeItem(id) {
    this.setItems(this.items.filter(item => item.id !== id))
  }

  @override sortItems({ dragIndex, hoverIndex }) {
    const newItems = [...this.items]
    const elementA = { ...newItems[dragIndex] }
    const elementB = { ...newItems[hoverIndex] }
    newItems[dragIndex] = elementB
    newItems[hoverIndex] = elementA
    this.setItems(newItems.map(item => new Row(this, item)))
  }

  @computed get showAdd() {
    return this.buttonsEditable
  }

  @computed get addText() {
    return this.showCompetencyLibrary ? 'Add custom competency' : ''
  }

  @override get dontKnowOption() {
    return {
      ...super.dontKnowOption,
      inactive: !stores.module.isResponsive
    }
  }
}
