import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'
import { Link, withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { Dedupe as DedupeIntegration } from '@sentry/integrations'
import Layout from '@ui/Layouts/Public'
import Error from '@ui/Error'
import { ENVIRONMENT } from '@constants'
import { IS_LOCAL } from '../../constants'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      eventId: null,
      hasError: false
    }
    this.isTrackable = !IS_LOCAL
  }

  static getDerivedStateFromError() {
    return {
      hasError: true
    }
  }

  componentDidMount() {
    if (this.isTrackable) {
      const dsn =
        'https://4fb616bc1a8b429baf23bb40fe32a8cd@o171191.ingest.sentry.io/6399814'

      Sentry.init({
        dsn,
        integrations: [new DedupeIntegration()],
        ignoreErrors: [
          'Non-Error exception captured',
          'Non-Error promise rejection captured',
          `TypeError: undefined is not an object (evaluating 'o.state')`
        ],
        environment: ENVIRONMENT
      })
    }

    window.onerror = (_, __, ___, ____, error) => Sentry.captureException(error)
  }

  componentDidCatch(error) {
    if (
      /loading chunk \d* failed./i.test(error.message) ||
      /Loading CSS chunk \d* failed./i.test(error.message)
    ) {
      if (!sessionStorage.getItem('firstLoad')) {
        sessionStorage.setItem('firstLoad', 'true')
        Sentry.captureMessage('Reloading page...')
        window.location.reload(true)
      } else {
        sessionStorage.removeItem('firstLoad')
        Sentry.captureMessage('Error. Already reloaded!')
      }
    } else {
      Sentry.captureException(error)
    }
  }

  apiErrorFrom = module => {
    const {
      location: { search }
    } = this.props
    const qs = queryString.parse(search)
    return qs.from && qs.from.includes(module)
  }

  errorFrom = module => {
    const {
      location: { pathname }
    } = this.props
    return pathname.includes(module)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Layout>
          <Layout.Main>
            <Error
              color='red'
              description='We had a problem processing your request.'
              iconName='times circle'
              title='Sorry!'
            >
              <small className='contact-support'>
                <Link to=''>Click here</Link>
                {` to try again or `}
                <a
                  href='https://www.crosschq.com/customer-support'
                  target='_blank'
                  rel='noreferrer'
                >
                  contact support
                </a>
                {`.`}
              </small>
            </Error>
          </Layout.Main>
        </Layout>
      )
    }
    return this.props.children || null
  }
}

export default withRouter(ErrorBoundary)
