import { toast } from 'react-toastify'

export const showNotification = (message = '', type = 'positive') => {
  if (type === 'positive') {
    type = 'success'
  }
  if (type === 'negative') {
    type = 'error'
  }

  if (!toast.isActive(message)) {
    toast(message, { type: type, toastId: message })
  }
}

export const isValidColor = (color = '') => {
  const rgbPattern = /^(\d{1,3}),(\d{1,3}),(\d{1,3})$/
  return color.match(rgbPattern)
}
