import { computed, override } from 'mobx'
import MultipleChoice from '../../models/Question/MultipleChoice'
import Question from './index'

export default class EditorMultipleChoice extends Question(MultipleChoice) {
  @computed get defaultItem() {
    return { text: 'New option', value: `option_${new Date().getTime()}` }
  }

  @computed get defaultChoices() {
    return Array(2)
      .fill(0)
      .map((_, index) => ({
        text: `Option ${index + 1}`,
        value: `option_${index + 1}`
      }))
  }

  @override get optionsParams() {
    return {
      ...super.optionsParams,
      choices: this.desktopItems.length ? this.desktopItems : undefined
    }
  }

  @computed get canRemove() {
    return this.items.length > 2
  }

  @override get choices() {
    const choices = super.choices.map(choice => {
      if (choice.value === 'none' || choice.value === 'other')
        return { ...choice, editable: false, inactive: true }
      return {
        ...choice,
        editable: this.buttonsEditable,
        inactive: !this.buttonsEditable,
        removable: this.buttonsEditable && this.canRemove
      }
    })

    return choices
  }

  @computed get showAdd() {
    return this.buttonsEditable
  }
}
