import { computed, observable, makeObservable } from 'mobx'

export default class Competency {
  @observable can_edit = false
  @observable codename = ''
  @observable created_by = ''
  @observable id = ''
  @observable text = ''

  constructor(competenciesStore, props) {
    this.competenciesStore = competenciesStore
    makeObservable(this)
    this.can_edit = props.can_edit || false
    this.codename = props.codename || ''
    this.created_by = props.created_by || ''
    this.id = props.id || ''
    this.text = props.text || ''
  }

  @computed get selected() {
    return this.competenciesStore.selected.some(
      ({ value }) => this.codename === value
    )
  }

  @computed get isNew() {
    const competency = this.competenciesStore.selected.find(
      ({ value }) => this.codename === value
    )
    return competency ? competency.is_new : false
  }

  @computed get disabled() {
    return this.selected && !this.isNew
  }
}
