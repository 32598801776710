import { action, computed, observable } from 'mobx'
import { arrayMove } from '@helpers/array'
import sortBy from 'lodash/sortBy'
import Column from './Column'

export default class Columns {
  @observable list = []
  @observable ids = {}
  @observable properties = []
  @observable edit_columns = false
  @observable is_editable = true

  constructor(list = []) {
    this.set(list)
  }

  @action updateColumnsStatus = selected => {
    this.editable.forEach(column => column.setActive(selected.includes(column.id)))
    this.sortDesactivated()
    this.autoOrder()
  }

  @action autoOrder() {
    this.list.forEach((col, index) => col.setOrder(index))
  }

  @action sortByOrder() {
    this.list = sortBy(this.list, 'order')
  }

  @action sortDesactivated() {
    this.list = [...this.active, ...sortBy(this.desactive, 'name')]
  }

  @action set(columns = []) {
    this.list = columns.map(column => {
      const props = this.properties.find(({ id }) => id === column.field_name)
      return new Column(this, { ...column, ...props })
    })
    this.sortByOrder()
    this.autoOrder()
  }

  @action move(fromIndex, toIndex) {
    this.list = [...this.noEditable, ...arrayMove(this.editable, fromIndex, toIndex)]
    this.autoOrder()
  }

  @action reorder(columns) {
    this.list.forEach(column => {
      column.setOrder(columns.findIndex(({ id }) => id === column.id))
    })

    this.sortByOrder()
  }

  @action toggleEditColumns() {
    this.edit_columns = !this.edit_columns
  }

  @action setActive(columnId, value) {
    const column = this.list.find(column => column.id === columnId)
    if (column) column.setActive(value)
  }

  @computed get idsList() {
    return this.editable.map(({ id }) => id)
  }

  @computed get selectedIdsList() {
    return this.editableAndActive.map(({ id }) => id)
  }

  @computed get active() {
    return this.list.filter(column => column.is_active)
  }

  @computed get desactive() {
    return this.list.filter(column => !column.is_active)
  }

  @computed get editable() {
    return this.list.filter(column => column.is_editable)
  }

  @computed get noEditable() {
    return this.list.filter(column => !column.is_editable)
  }

  @computed get editableAndActive() {
    return this.editable.filter(column => column.is_active)
  }

  @computed get params() {
    return this.list.map(column => column.params)
  }

  @computed get editableParams() {
    return this.editable.map(column => column.params)
  }

  @computed get toJson() {
    return this.list.map(column => column.params)
  }

  @computed get isEmpty() {
    return this.editable.length === 0
  }

  @computed get hasSelected() {
    return this.active.length > 0
  }

  @computed get canEdit() {
    return this.is_editable
  }

  @computed get fixed() {
    return this.list.filter(({ fixed }) => fixed)
  }
}
