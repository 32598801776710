import avgIndicator from '@images/ReportVisualization/avg-indicator.svg'
import bodyIndicator from '@images/ReportVisualization/body-counters.svg'
import countersIndicator from '@images/ReportVisualization/counters.svg'
import rawData from '@images/ReportVisualization/raw-data.svg'
import halfpieGraph from '@images/ReportVisualization/halfpie-graph.svg'
import none from '@images/ReportVisualization/none.svg'
import table from '@images/ReportVisualization/table.svg'
import versus from '@images/ReportVisualization/versus.svg'

export const BASE_URL_API = process.env.REACT_APP_BASE_URL_API
export const BASE_URL_API_OLD = process.env.REACT_APP_BASE_URL_API_OLD
export const BASE_URL_AUTH_API = process.env.REACT_APP_BASE_URL_AUTH_API
export const TERMS_URL = process.env.REACT_APP_TERMS_URL
export const POLICY_URL = process.env.REACT_APP_POLICY_URL
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
export const ORG_BASE_URL = process.env.REACT_APP_ORG_BASE_URL
export const CUSTOM_MODULES_BASE_URL = `${ORG_BASE_URL}/settings/org/survey/custom-questions`
export const IS_LOCAL = process.env.REACT_APP_ENVIRONMENT === 'local'
export const KNOWLEDGE_URL = 'https://support.crosschq.com/knowledge'
export const FP_ACTIVE = process.env.REACT_APP_FP_ACTIVE
export const FP_CLIENT_TOKEN = process.env.REACT_APP_FP_CLIENT_TOKEN
export const FP_ENDPOINT = process.env.REACT_APP_FP_ENDPOINT
export const REGION = process.env.REACT_APP_REGION

export const MODULES = {
  VOICE: 'crosschq-pulse-plus',
  CQ360: 'crosschq-360'
}

export const QUESTIONS_TYPES = {
  MULTIPLE_CHOICE: 'radiogroup',
  OPEN_TEXT: 'text',
  COMMENT: 'comment',
  MULTIPLE_SELECTION: 'checkbox',
  RATING: 'rating',
  MULTIPLE_RATING: 'matrix',
  COUNTER: 'counter',
  NPS: 'nps',
  NPS_RATING: 'nps-rating'
}

export const REPORT_VISUALIZATION_ICONS = {
  'average-indicator': avgIndicator,
  'body-counter': bodyIndicator,
  counter: countersIndicator,
  none: none,
  'table-with-bar-chart': rawData,
  'half-pie-graph': halfpieGraph,
  plain: table,
  versus: versus
}

export const EMPTY_FIELD = '---'

export const TOGGLE_SETTINGS = {
  NONE: 'Show "None" Option',
  OTHER: 'Show "Other" Option',
  DONT_KNOW: `Show "I don't know" Option`,
  SKIP_FOLLOW_UP: `Skip "follow-up" Question`
}

export const VARIABLES_OPTIONS = [
  { text: 'Candidate Name', value: '{candidate}', candidates: false },
  { text: 'Candidate/Reference Company', value: '{company}', candidates: false },
  {
    text: 'Candidate/Reference Relationship',
    value: '{relationship}',
    candidates: false
  },
  { text: 'My Organization Name', value: '{organization}', candidates: true }
]

export const REFERENCES_VARIABLES_OPTIONS = VARIABLES_OPTIONS.map(
  ({ text, value }) => ({ text, value })
)

export const CANDIDATES_VARIABLES_OPTIONS = VARIABLES_OPTIONS.filter(
  ({ candidates }) => candidates
).map(({ text, value }) => ({ text, value }))
