import { computed, makeObservable, override, action } from 'mobx'
import MultipleChoice from '../../models/Question/MultipleChoice'
import Question from './index'

export default class MultipleChoiceSurvey extends Question(MultipleChoice) {
  constructor(previewStore, props = {}) {
    super(previewStore, props)
    makeObservable(this)
  }

  @computed get otherSelected() {
    return (
      this.answer === 'other' ||
      (this.answer && !this.items.some(item => item.value === this.answer))
    )
  }

  @computed get isOtherOptionSelectedEmpty() {
    return this.formValue === ''
  }

  @override get hasValidAnswer() {
    return this.otherSelected ? !this.isOtherOptionSelectedEmpty : !!this.answer
  }

  @computed get showForm() {
    return this.otherSelected
  }

  @computed get formValue() {
    return this.answer === 'other' ? '' : this.answer
  }

  @computed get formPlaceholder() {
    return this.otherPlaceholder
  }

  @computed get hasOther() {
    return this.otherActive
  }

  @computed get noteAnswerValue() {
    return this.note
  }

  @action wasNotePartiallyAnswered(answer) {
    this.note = answer
  }

  @override get answerParsed() {
    if (this.otherSelected) return this.answer
    const option = this.choices.find(item => item.value === this.answer)
    return option ? option.text : ''
  }

  @override get choices() {
    return super.choices.map(item => ({
      ...item,
      selected:
        item.value === 'other' ? this.otherSelected : item.value === this.answer,
      text: this.previewStore.renderTokens(item.text)
    }))
  }
}
