import React from 'react'

function Content({ tooltip }) {
  const { title, description } = tooltip

  return title || description ? (
    <div className='flex vertical'>
      {title && <span className='text bold title mb-4'>{title}</span>}
      {description.map(({ label, value }, index) => (
        <div key={index} className='flex'>
          {label && <span className='text white mr-4'>{`${label}:`}</span>}
          {value && <p className='margin none text gray-light'>{value}</p>}
        </div>
      ))}
    </div>
  ) : (
    tooltip
  )
}

export default Content
