import { observable, computed, action, makeObservable, override } from 'mobx'
import { surveyApi, REDIRECTING, updateSurveyApi } from '@api'
import SurveyState from '../index'

class SurveyUser extends SurveyState {
  @observable id = ''
  @observable storingPartial = false
  @observable submitting = false
  @observable answersCopy = {}
  @observable authentication = {}
  @observable redirecting = false
  @observable pre_answers = {}

  constructor(rootStore) {
    super(rootStore)
    makeObservable(this)
  }

  @computed get isPreview() {
    return false
  }

  @computed get showBanner() {
    return this.info.show_crosschq_info
  }

  @computed get isRedirecting() {
    return this.redirecting
  }

  @computed get isSelfAssessment() {
    return this.info.audience === 'Candidates'
  }

  @computed get hasPreAnswer() {
    return Object.keys(this.pre_answers).length > 0
  }

  @computed get questionPreAnswered() {
    let position = -1

    for (let i = 0; i < this.steps.length; i++) {
      const current = this.steps[i]

      if (current.isQuestion) {
        if (
          Object.keys(this.pre_answers).includes(current.codename) &&
          current.isQuestionEnabled(this.answers, this.tokens)
        ) {
          position = i
          break
        }
      }
    }
    return position
  }

  @computed get questionPreAnsweredId() {
    return Object.keys(this.pre_answers)[0]
  }

  @action setParams(id, { language, ...authentication }) {
    this.setId(id)
    this.setLanguage(language)
    this.setAuthentication(authentication)
    updateSurveyApi(this.id, this.authentication)
  }

  @action setId(id) {
    this.id = id
  }

  @action setAuthentication({ signature, source }) {
    this.authentication = {
      signature: signature,
      source: source
    }
  }

  @action setPreAnswer(question_id, action) {
    this.pre_answers = { [question_id]: parseInt(action) }
  }

  @action resetPreAnswer() {
    this.pre_answers = {}
  }

  @action saveNoteAnswer(note) {
    const mapped = this.currentStep

    mapped.wasNoteAnswered(note)
    if (mapped.isQuestion) {
      // If is not saving the answer for the first time
      // we should review following answers' rules
      const pos = mapped.codename in this.noteAnswers
      if (pos > -1) {
        // Reset followings when is changing the answer
        if (this.noteAnswersCopy[mapped.codename] !== note) {
          this.resetNoteAllFrom(mapped.codename)
        }
      }
      this.noteAnswers[mapped.codename] = note
    }
  }

  @action resetNoteAllFrom(from = '') {
    let reset = false
    this.steps.map(mapped => {
      if (reset) {
        mapped.note = null
        delete this.noteAnswers[mapped.codename]
      }
      if (from === mapped.codename) {
        reset = true
      }
      return mapped
    })
  }

  @action setError = error => {
    this.error = error
    this.redirecting = error === REDIRECTING
  }

  @action setStoringPartial = value => {
    this.storingPartial = value
  }

  @action setSubmitting = value => {
    this.submitting = value
  }

  @action resumeSurvey() {
    const currentStep = this.lastQuestionAnswered + 1
    if (this.hasPreAnswer) {
      this.saveAnswers(this.questionPreAnsweredId)
      this.resetPreAnswer()
    }
    this.setCurrentStep(currentStep)
  }

  @action startSurvey() {
    this.resumeSurvey()
  }

  @action async changeLanguage(language) {
    this.changing_language = true
    this.setLanguage(language)
    await this.getSurvey()
    this.changing_language = false
  }

  @action getSurvey = async () => {
    return surveyApi
      .get('/', { params: { language: this.language_code } })
      .then(({ data }) => {
        if (data.survey.length > 0) {
          this.initSurvey(data.survey)
          this.setAnswers({ ...data.answers, ...this.pre_answers })
          this.answersCopy = { ...data.answers, ...this.pre_answers }
          this.setTokens(data.context)
          this.setSteps(this.survey.steps)
          this.version = data.version
          this.noteAnswers = data.notes
          this.info = data.info || {}
        } else {
          this.error = 'empty'
        }
      })
      .catch(error => {
        this.setError(error.message)
        return false
      })
  }

  @override saveAnswers = async questionId => {
    this.setStoringPartial(true)
    this.version = this.version + 1

    return surveyApi
      .put('/answer/', {
        version: this.version,
        answers: this.answers,
        notes: this.noteAnswers,
        languages: { [questionId]: this.language_code }
      })
      .then(() => {})
      .catch(error => {
        this.setError(error.message)
        return false
      })
      .finally(() => this.setStoringPartial(false))
  }

  @action submitSurvey = async () => {
    this.setSubmitting(true)
    return surveyApi
      .post(
        '/submit/',
        {
          version: this.version,
          answers: this.answers,
          notes: this.noteAnswers
        },
        {
          params: { language: this.language_code }
        }
      )
      .then(response => {
        this.redirecting = response.message === REDIRECTING
      })
      .catch(error => {
        this.setError(error.message)
        return false
      })
      .finally(() => this.setSubmitting(false))
  }

  @action getLanguages = () =>
    surveyApi
      .get('/languages/')
      .then(response => this.setLanguages(response.data))
      .catch(error => this.setError(error.message))
}

export default SurveyUser
