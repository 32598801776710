import { override, observable } from 'mobx'
import Profile from '.'

class OrganizationUser extends Profile {
  @observable can_access_alpha_features = false
  @observable can_access_beta_features = false

  constructor(props = {}) {
    super(props)
    this.can_access_alpha_features = props.can_access_alpha_features || false
    this.can_access_beta_features = props.can_access_beta_features || false
  }

  @override init(props = {}) {
    super.init(props)
    this.can_access_alpha_features = props.can_access_alpha_features || false
    this.can_access_beta_features = props.can_access_beta_features || false
  }

  @override reset() {
    super.reset()
    this.can_access_alpha_features = false
    this.can_access_beta_features = false
  }
}

export default OrganizationUser
