import { action, computed, observable, makeObservable } from 'mobx'
import {
  CANDIDATES_VARIABLES_OPTIONS,
  REFERENCES_VARIABLES_OPTIONS,
  MODULES
} from '@constants'
import stores from '../'

export default class Module {
  @observable name = ''
  @observable audience = ''
  @observable audience_name = ''
  @observable codename = ''
  @observable display_audience = ''
  @observable description = ''
  @observable title = ''
  @observable questionsCount = 0
  @observable completion = 0
  @observable is_complete = false
  @observable is_active = false
  @observable is_required = true
  @observable is_valid = true
  @observable questions = []
  @observable configurations = {}
  @observable options = []
  @observable source = MODULES.CQ360
  @observable error = null
  @observable loading_question = false
  @observable language_code = ''
  @observable has_questions = false
  @observable has_updates = false
  @observable status = { category: '', text: '' }
  @observable original_module = {
    title: '',
    description: ''
  }

  constructor(props) {
    makeObservable(this)
    this.init(props)
  }

  @computed get language() {
    const language = stores.appState.getLanguageByCode(this.language_code)
    return language || { code: this.language_code }
  }

  @action init(props = {}) {
    this.id = props.id || ''
    this.codename = props.codename || ''
    this.name = props.name || ''
    this.audience = props.audience || ''
    this.audience_name = props.audience_name || this.audience_name
    this.display_audience = props.display_audience || ''
    this.description = props.description || ''
    this.title = props.title || ''
    this.questionsCount = props.questionsCount || 0
    this.completion = props.completion || 0
    this.is_complete = props.is_complete || false
    this.status = props.status || { category: '', text: '' }
    this.is_active = props.is_active || false
    this.language_code = props.language
    this.title = props.title
    this.has_questions = props.has_questions
    this.has_updates = props.has_updates
    this.original_module = props.original_module || {
      title: '',
      description: ''
    }
  }

  @action setQuestions(list) {
    this.questions = list || []
  }

  @action setConfiguration(conf) {
    this.configurations = conf || {}
  }

  @action setOptions(options) {
    this.options =
      options.map(option => ({ value: `{${option.value}}`, text: option.name })) ||
      []
  }

  @action setSource(source) {
    this.source = source || MODULES.CQ360
  }

  @computed get isRequired() {
    return this.is_required
  }

  @computed get isValid() {
    return this.is_valid
  }

  @computed get isPreviewEnabled() {
    return this.is_active && this.has_questions && this.has_updates
  }

  @computed get isSelfAssessment() {
    return this.audience === 'candidates'
  }

  @computed get hasVarOptions() {
    return this.source === MODULES.VOICE
  }

  @computed get variablesOptions() {
    if (this.source === MODULES.VOICE) {
      return this.options
    } else {
      return this.isSelfAssessment
        ? CANDIDATES_VARIABLES_OPTIONS
        : REFERENCES_VARIABLES_OPTIONS
    }
  }

  @computed get audienceName() {
    return this.audience_name
  }

  @computed get isResponsive() {
    return stores.appState.windowWidth < 1137
  }

  @computed get isVoiceModule() {
    return this.source === MODULES.VOICE
  }
}
