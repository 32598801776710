import React from 'react'
import ReactCountryFlag from 'react-country-flag'
import cn from 'classnames'
import './flag.scss'

const Flag = ({ codename, onClick, className, size = 30, bordered }) => (
  <div onClick={onClick} className={cn('flag', className, `x${size}`, { bordered })}>
    <ReactCountryFlag countryCode={codename} svg />
  </div>
)

export default Flag
