export function arrayMoveMutate(array, from, to) {
  const startIndex = to < 0 ? array.length + to : to
  const item = array.splice(from, 1)[0]
  array.splice(startIndex, 0, item)
}

export function arrayMove(array, from, to) {
  array = array.slice()
  arrayMoveMutate(array, from, to)
  return array
}

export const chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  )
