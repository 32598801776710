import { action, computed, makeObservable, observable } from 'mobx'
import QuestionModel from '../../models/Question'

export default function Question(parentClass = QuestionModel) {
  return class QuestionClass extends parentClass {
    @observable position = 0
    @observable active = false
    @observable answered = false
    @observable skipped = false
    @observable note = null
    @observable noneSelected = false
    @observable dirty = false
    @observable answer = undefined

    constructor(previewStore, props = {}) {
      super(props)
      makeObservable(this)
      this.previewStore = previewStore
      this.initQuestion(props)
    }

    @action initQuestion(props) {
      this.position = props.position || 0
    }

    @computed get wasAnswered() {
      return this.answered
    }

    @computed get visibleIf() {
      return this.options.raw.visible_if
    }

    saveAnswer = answer => {
      this.setAnswer(answer)
    }

    @action setAnswer = answer => {
      this.answer = answer
      this.dirty = true
    }

    @action setAnswered(value) {
      this.answered = value
    }

    @action skip = () => {
      this.skipped = true
    }

    @action resetAnswer() {
      this.active = false
      this.answered = false
      this.skipped = false
      this.note = null
      this.noneSelected = false
      this.dirty = false
      this.answer = undefined
    }

    matchingRules = (answers, tokens) =>
      this.visibleIf ? this.isQuestionEnabled(answers, tokens) : true

    isQuestionEnabled = (answers, tokens) => {
      if (!this.visibleIf || !answers) return true

      let rulesParsed = this.visibleIf
      rulesParsed = rulesParsed.replace(/ and /g, ' && ')
      rulesParsed = rulesParsed.replace(/ or /g, ' || ')
      rulesParsed = rulesParsed.replace(/ = /g, ' === ')
      rulesParsed = rulesParsed.replace(/ <> /g, ' !== ')

      rulesParsed = rulesParsed.replace(/\{token_(.*?)\}/g, (_, val) => {
        let value = answers[val]
        if (typeof value === 'string') {
          value = `"${value}"`
        }
        return value
      })

      rulesParsed = rulesParsed.replace(/\{(.*?)\}/g, (_, val) => {
        let value = answers[val]
        if (value === null || typeof value === 'undefined') {
          value = tokens[val]
        }

        if (typeof value === 'string') {
          value = `"${value}"`
        }

        return value
      })

      /* eslint-disable no-eval */
      return rulesParsed ? eval(rulesParsed) : true
      /* eslint-enable no-eval */
    }

    @computed get hasValidAnswer() {
      return !!this.answer
    }

    @computed get isValid() {
      return this.hasValidAnswer
    }

    @computed get answerParsed() {
      return this.answer
    }

    @computed get hasPerfectScore() {
      return false
    }

    @computed get partiallyAnswered() {
      return this.answer && this.answer.length > 0
    }

    @computed get tags() {
      const list = []

      if (this.isOptional) list.push('Optional question')

      return list
    }

    @action reset() {
      this.answer = undefined
      this.answered = undefined
    }
  }
}
