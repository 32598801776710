import { action, computed, makeObservable, override } from 'mobx'
import MultipleSelection from '../../models/Question/MultipleSelection'
import Question from './index'

export default class EditorMultipleSelection extends Question(MultipleSelection) {
  constructor(moduleStore, props = {}) {
    super(moduleStore, props)
    makeObservable(this)
    this.init()
  }

  @computed get defaultItem() {
    return { text: 'New option', value: `option_${new Date().getTime()}` }
  }

  @computed get defaultChoices() {
    return Array(2)
      .fill(0)
      .map((_, index) => ({
        text: `Option ${index + 1}`,
        value: `option_${index + 1}`
      }))
  }

  @override get optionsParams() {
    return {
      ...super.optionsParams,
      choices: this.desktopItems
    }
  }

  @action init() {
    this.setItems(
      this.desktopItems.length === 0 ? this.defaultChoices : this.desktopItems
    )
  }

  @computed get canRemove() {
    return this.items.length > 2
  }

  @override get choices() {
    const choices = super.choices.map(choice => {
      if (choice.value === 'none' || choice.value === 'other')
        return { ...choice, editable: false, inactive: true }
      return {
        ...choice,
        editable: this.buttonsEditable,
        inactive: !this.buttonsEditable,
        removable: this.buttonsEditable && this.canRemove
      }
    })

    return choices
  }

  @computed get showAdd() {
    return this.buttonsEditable
  }
}
