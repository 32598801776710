import React, { Component } from 'react'
import cn from 'classnames'
import { TERMS_URL, POLICY_URL } from '@constants'
import watermark from '@images/Survey/watermark.svg'

export default class Footer extends Component {
  render() {
    const { showCrosschqLogo = false } = this.props
    const year = new Date().getFullYear()

    return (
      <div className={cn('content-footer flex', { center: !showCrosschqLogo })}>
        <div>
          <div className='link-container'>
            <a href={POLICY_URL} target='_blank' rel='noreferrer'>
              Privacy Policy
            </a>
            <span style={{ color: '#bbb' }}> • </span>
            <a
              href='https://www.crosschq.com/customer-support'
              target='_blank'
              rel='noreferrer'
            >
              Feedback
            </a>
          </div>
          <div>
            <p dir='ltr'>© {year} Crosschq, All rights reserved.</p>
          </div>
        </div>
        {showCrosschqLogo && (
          <div>
            <a href={TERMS_URL} target='_blank' rel='noreferrer'>
              <img src={watermark} />
            </a>
          </div>
        )}
      </div>
    )
  }
}
