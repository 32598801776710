import { action, computed, makeObservable } from 'mobx'
import shuffle from 'lodash/shuffle'
import { QUESTIONS_TYPES } from '@constants'
import Question from './index'

export default class MultipleSelection extends Question {
  constructor(props = {}) {
    super({ question_type: QUESTIONS_TYPES.MULTIPLE_SELECTION, ...props })
    makeObservable(this)
  }

  @computed get hasNotes() {
    return this.options.raw.has_notes
  }

  @computed get notesText() {
    return this.options.raw.notes_text
  }

  @computed get notesPlaceHolder() {
    return this.options.raw.notes_placeholder
  }

  @computed get choices() {
    const choices = [...this.items]

    if (this.noneActive) {
      choices.push(this.noneOption)
    }

    if (this.otherActive) {
      choices.push(this.otherOption)
    }

    return choices
  }

  @computed get removeChoicesSelectedIn() {
    return this.options.raw.remove_choices_selected_in || ''
  }

  @computed get hasRemovedChoices() {
    return this.removeChoicesSelectedIn.length > 0
  }

  @computed get otherPlaceholder() {
    return this.options.raw.other_placeholder
  }

  @computed get choicesOrder() {
    return this.options.raw.choices_order || ''
  }

  @computed get items() {
    return this.choicesOrder === 'random'
      ? shuffle(this.options.choices)
      : this.options.choices
  }

  @computed get desktopItems() {
    return this.desktop_options.choices
  }

  @action setItems(choices) {
    this.desktop_options.setChoices(choices)
  }
}
