import { action, observable, computed, makeObservable } from 'mobx'
import queryString from 'query-string'

const PAGE_SIZE_DEFAULT = 10

export default class Pagination {
  @observable page = 1
  @observable total_pages = 0
  @observable total = 0
  @observable results_per_page = PAGE_SIZE_DEFAULT

  constructor(props = {}) {
    makeObservable(this)

    this.page = props.page || 1
    this.total = props.total || 0
    this.results_per_page = props.results_per_page || PAGE_SIZE_DEFAULT
    this.total_pages =
      props.total_pages || Math.ceil(this.total / this.results_per_page)
  }

  @computed get pages() {
    return this.total_pages
  }

  @computed get activePage() {
    return this.page
  }

  @computed get isEmpty() {
    return this.total === 0
  }

  @computed get hasMorePages() {
    return this.activePage < this.pages
  }

  @computed get hasPages() {
    return this.pages > 1
  }

  @computed get resultsPerPage() {
    return this.results_per_page
  }

  @computed get totalRecords() {
    return this.total
  }

  @computed get resultsRange() {
    const from = (this.page - 1) * this.results_per_page + 1
    const to = from + this.results_per_page - 1
    return `${this.total > 0 ? from : 0}-${to > this.total ? this.total : to}`
  }

  @computed get show() {
    return this.total > this.results_per_page || this.pages > 1
  }

  @computed get isFirstPage() {
    return this.activePage === 1
  }

  @action nextPage() {
    if (this.hasMorePages) this.page = this.page + 1
  }

  @action previousPage() {
    if (this.page > 1) this.page = this.page - 1
  }

  @action resetPage() {
    this.page = 1
  }

  @action setPage(page = 1) {
    this.page = Number(page) || 1
  }

  @action setPageSize(pageSize) {
    this.results_per_page = pageSize || PAGE_SIZE_DEFAULT
  }

  @action reset() {
    this.page = 1
    this.total_pages = 0
    this.total = 0
    this.results_per_page = PAGE_SIZE_DEFAULT
  }

  @action getFromURL() {
    const { page } = queryString.parse(location.search) || {}
    this.page = page || 1
  }

  @computed get params() {
    return { page: this.activePage, page_size: this.resultsPerPage }
  }

  @action setValues(values) {
    this.page = values.page !== undefined ? values.page : this.page
    this.total = values.total !== undefined ? values.total : this.total
    this.results_per_page =
      values.results_per_page !== undefined
        ? values.results_per_page
        : this.results_per_page
    this.total_pages =
      values.total_pages !== undefined
        ? values.total_pages
        : Math.ceil(this.total / this.results_per_page)
  }

  init(
    data = { page: 1, total_pages: 0, total: 0, results_per_page: PAGE_SIZE_DEFAULT }
  ) {
    this.page = data.page || 1
    this.total = data.total || 0
    this.results_per_page = data.results_per_page || PAGE_SIZE_DEFAULT
    this.total_pages =
      data.total_pages || Math.ceil(this.total / this.results_per_page)
    return this
  }
}
