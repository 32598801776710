import { observable, makeObservable, action, computed } from 'mobx'

export default class Options {
  @observable raw = {}
  @observable other_active = false
  @observable none_active = false
  @observable dont_know_active = false
  @observable min_count = 0
  @observable max_count = 3

  constructor(props = {}) {
    makeObservable(this)

    this.set(props)
  }

  @action set(props = {}) {
    this.raw = props || this.raw
    this.other_active =
      'has_other' in this.raw ? this.raw.has_other : this.other_active
    this.none_active = 'has_none' in this.raw ? this.raw.has_none : this.none_active
    this.dont_know_active =
      'has_dont_know' in this.raw ? this.raw.has_dont_know : this.dont_know_active

    const { validators = [] } = this.raw
    const { min_count, max_count } = validators[0] || {}

    this.min_count = min_count || this.min_count
    this.max_count = max_count || this.max_count
  }

  @action setLayout(value) {
    this.raw.layout = value
  }

  @action setRows(rows) {
    this.raw.rows = rows
  }

  @action setChoices(choices) {
    this.raw.choices = choices
  }

  @action setColumns(columns) {
    this.raw.columns = columns
  }

  @action setRateValues(rate_values) {
    this.raw.rate_values = rate_values
  }

  @action setDontKnowActive(value) {
    this.dont_know_active = value
  }

  @action setNoneActive(value) {
    this.none_active = value
  }

  @action setOtherActive(value) {
    this.other_active = value
  }

  @computed get layout() {
    return this.raw.layout || 'inline'
  }

  @computed get rows() {
    return this.raw.rows || []
  }

  @computed get choices() {
    return this.raw.choices || []
  }

  @computed get columns() {
    return this.raw.columns || []
  }

  @computed get rateValues() {
    return this.raw.rate_values || []
  }

  @computed get isCondensed() {
    return this.raw.is_condensed
  }

  @computed get dontKnowActive() {
    return this.dont_know_active
  }

  @computed get noneActive() {
    return this.none_active
  }

  @computed get otherActive() {
    return this.other_active
  }
}
