import { computed, observable } from 'mobx'
import QuestionEditor from '@stores/Editor/Question'

export default class Question extends QuestionEditor() {
  @observable categories = []
  @observable creator = ''
  @observable default_data = {}
  @observable tags = []

  constructor(moduleStore, props) {
    super(moduleStore, props)
    this.categories = props.categories || this.categories
    this.creator = props.creator || this.creator
    this.default_data = props.default_data || this.default_data
    this.tags = props.tags || this.tags
  }

  @computed get categoriesNames() {
    return this.categories.map(({ name }) => name)
  }
}
