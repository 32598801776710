import React, { Fragment } from 'react'
import View from '@ui/View'
import ViewMessage from '@ui/View/Message'
import Divider from '@ui/Divider'

function Error({ children, color, description, iconName, title }) {
  return (
    <View className='error-view'>
      <ViewMessage>
        <ViewMessage.Icon
          name={iconName}
          size='big'
          className={`${color} icon-background`}
        />
        <ViewMessage.Title>{title}</ViewMessage.Title>
        <ViewMessage.Content>{description}</ViewMessage.Content>
      </ViewMessage>
      {children && (
        <Fragment>
          <Divider hidden />
          {children}
        </Fragment>
      )}
    </View>
  )
}

export default Error
