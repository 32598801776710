import React from 'react'
import { Loader as SLoader } from 'semantic-ui-react'

function Loader({ text = '', ...props }) {
  return (
    <SLoader active indeterminate {...props}>
      {text}
    </SLoader>
  )
}

export default Loader
