import { action, computed, observable, makeObservable } from 'mobx'
import { getSubmitSurveyPage } from './utils'

export default class Survey {
  @observable modules = []

  constructor() {
    makeObservable(this)
  }

  @action setModules(modules) {
    this.modules = modules
  }

  @action resetAnswers() {
    this.steps.forEach(step => {
      if (step.resetAnswer) step.resetAnswer()
    })
  }

  @computed get steps() {
    const steps = []

    this.modules.forEach(module => {
      if (module.questions.length > 0) {
        const questions = module.questions.map(question => {
          question.moduleTitle = module.title
          question.moduleDescription = module.description
          return question
        })
        steps.push(...questions)
      } else if (module.title || module.description) {
        steps.push(module)
      }
    })

    return [...steps, getSubmitSurveyPage()]
  }

  @computed get stepsAllMode() {
    const steps = []

    this.modules.forEach(module => {
      if (module.title || module.description) steps.push(module)
      if (module.questions.length > 0) steps.push(...module.questions)
    })

    return steps
  }
}
