import { computed, observable } from 'mobx'

export default class ScalesWording {
  @observable codename = ''
  @observable default_data = {}
  @observable name = ''
  @observable is_default = false

  constructor(props = {}) {
    this.codename = props.codename || this.codename
    this.default_data = props.default_data || this.default_data
    this.name = props.name || this.name
    this.is_default = props.is_default || this.is_default
  }

  @computed get scale() {
    return this.default_data.rate_values || []
  }
}
