import { chunk } from '@helpers/array'

export function parseLegends(items = [], condensed) {
  const count = items.length
  return items.map((item, index) => {
    const isFirst = index === 0
    const isLast = index === count - 1
    const isMiddle = index === count / 2 - 1
    const hasText = String(item.text).includes('-')
    const hasLegend = condensed && (isFirst || isLast || isMiddle) && hasText

    return {
      ...item,
      text: hasLegend ? item.value : item.text,
      legend: hasLegend ? item.text : null
    }
  })
}

export function parseRates(rates, splitted) {
  const ratesSplitted = chunk(rates, splitted)
  const last = ratesSplitted[ratesSplitted.length - 1]
  const lastLength = last.length
  const diff = splitted - lastLength

  if (diff > 0) {
    const fakesLeft = Array(diff).fill({ fake: true })

    const fakesRight = Array(diff).fill({ fake: true })

    ratesSplitted[ratesSplitted.length - 1] = [...fakesLeft, ...last, ...fakesRight]
  }

  return ratesSplitted
}
