import { computed, observable, makeObservable, action } from 'mobx'
import { questionLibraryApi } from '@api'
import SuggestionsManager from '@stores/models/SuggestionsManager'
import Pagination from '@stores/models/Pagination'
import { showNotification } from '@utils'
import Question from './Question'

export default class QuestionLibrary {
  @observable list = []
  @observable categories = []
  @observable current_category = null
  @observable retrieving_questions = true
  @observable question_selected = null
  @observable type = []
  @observable current_type = null
  @observable search = null
  @observable total = 0
  @observable error = null
  @observable pagination = new Pagination()
  @observable removing = false
  @observable suggestionsManager = new SuggestionsManager({
    api: questionLibraryApi,
    endpoint: '/suggest/',
    responseParser: ({ questions }) => [
      {
        id: 'questions',
        items: questions.map(competency => ({
          id: competency,
          name: competency
        }))
      }
    ]
  })

  constructor(moduleStore) {
    this.moduleStore = moduleStore
    makeObservable(this)
  }

  @action setCategories(categories) {
    this.categories = categories
  }

  @action setList(list) {
    this.list = list.map(item => new Question(this.moduleStore, item))
  }

  @action getCategories() {
    return questionLibraryApi
      .get('/categories/', {
        params: { search: this.search, type: this.current_type }
      })
      .then(response => {
        this.error = null
        this.setCategories(response.data.filters)
        if (!this.current_category) {
          this.setCategory(this.categories[0].codename)
        }
        this.setTotal(response.data.totals)
      })
      .catch(error => {
        this.error = error
      })
  }

  @action getTypes() {
    return questionLibraryApi
      .get('/types/')
      .then(response => {
        this.error = null
        this.types = response.data.types
      })
      .catch(error => {
        this.error = error
      })
  }

  @action getList() {
    this.retrieving_questions = true
    return questionLibraryApi
      .get('/', {
        params: {
          page: this.pagination.page,
          type: this.current_type,
          search: this.search,
          category: this.current_category
        }
      })
      .then(response => response.data)
      .catch(error => {
        this.error = error
      })
  }

  @action async edit(question) {
    if (question.canUpdateToLibrary) {
      await this.update(question)
    } else {
      await this.create(question)
    }
  }

  @action async create(question) {
    this.creating = true
    return questionLibraryApi
      .post('/', {
        title: question.title,
        description: question.description,
        options: question.optionsParams,
        question_type: question.type,
        question_library_codename: question.question_library,
        question_component_codename: question.codename
      })
      .then(() => showNotification('The question has been added to the library'))
      .catch(error => {
        this.error = error
      })
      .finally(() => {
        this.creating = false
      })
  }

  @action async update(question) {
    this.creating = true
    return questionLibraryApi
      .put(`/${question.to_question_library}/`, {
        title: question.title,
        description: question.description,
        default_data: question.optionsParams,
        question_component_codename: question.codename,
        question_type: question.type
      })
      .then(() => showNotification('The question has been updated on the library'))
      .catch(error => {
        this.error = error
      })
      .finally(() => {
        this.creating = false
      })
  }

  @action async nextQuestionsPage() {
    if (this.pagination.hasMorePages) {
      this.pagination.nextPage()
      const response = await this.getList()
      if (response) {
        this.setList([...this.list, ...response.results])
      }
      this.retrieving_questions = false
    }
  }

  @action async getQuestions() {
    this.setList([])
    const response = await this.getList()

    if (response) {
      const { count, results } = response
      this.setList(results)
      this.pagination.setValues({
        total_pages: Math.ceil(count / 10),
        total: count,
        results_per_page: 10
      })
    }
    this.retrieving_questions = false
  }

  @action reset() {
    this.list = []
    this.categories = []
    this.current_category = null
    this.error = null
    this.retrieving_questions = true
    this.question_selected = null
    this.type = []
    this.current_type = null
    this.search = null
    this.pagination.reset()
    this.suggestionsManager.reset()
  }

  @action removeQuestion(codename) {
    this.removing = true

    return questionLibraryApi
      .delete(`/${codename}/`)
      .then(() => {
        this.getCategories()
        if (this.question_selected === codename) this.clearSelection()
        this.list = this.list.filter(item => item.codename !== codename)
      })
      .catch(error => {
        this.error = error
      })
      .finally(() => {
        this.removing = false
      })
  }

  @action setQuestionSelected(questionCodename) {
    this.question_selected = questionCodename
  }

  @action setType(type) {
    this.current_type = type
  }

  @action setCategory(category) {
    this.current_category = category
  }

  @action setSearch(text) {
    this.search = text
  }

  @action clearSelection() {
    this.question_selected = null
  }

  @action setTotal(total) {
    this.total = total
  }

  @computed get questions() {
    return this.list
  }

  @computed get questionSelected() {
    return this.question_selected
  }

  @computed get categoriesGrouped() {
    const groups = {}
    this.categories.forEach(category => {
      const list = groups[category.group] || []
      groups[category.group] = [...list, category]
    })

    return Object.values(groups)
  }
}
