import axios from 'axios'
import { action, computed, makeObservable, observable } from 'mobx'

export default class SuggestionsManager {
  @observable results = null
  @observable error = null
  @observable searching = false
  @observable endpoint = ''
  @observable responseParser = null
  @observable externalParams = {}
  @observable searchParam = 'search'
  @observable selected = {}
  @observable page = 1
  @observable has_next_page = true
  @observable start_page = 1

  constructor(props = {}) {
    makeObservable(this)
    this.endpoint = props.endpoint
    this.api = props.api || axios
    this.responseParser = props.responseParser
    this.searchParam = props.searchParam || this.searchParam
    this.start_page = props.start_page >= 0 ? props.start_page : this.start_page
    this.page = this.start_page
  }

  @action reset() {
    this.results = null
    this.selected = {}
    this.page = 1
    this.has_next_page = true
  }

  @action setExternalParams(value) {
    this.externalParams = value
  }

  @action setEndpoint(endpoint) {
    this.endpoint = endpoint
  }

  @action async get(search) {
    this.searching = true
    this.page = this.start_page
    this.has_next_page = true

    return this.api
      .get(this.endpoint, {
        params: {
          [this.searchParam]: search,
          page: this.page,
          ...this.externalParams
        }
      })
      .then(response => {
        const { data = [] } = response || {}
        this.results = this.responseParser ? this.responseParser(data) : data
      })
      .catch(error => {
        this.error = error
      })
      .finally(() => {
        this.searching = false
      })
  }

  @action async nextPage(search) {
    if (!this.has_next_page) return

    this.searching = true
    this.page = this.page + 1

    return this.api
      .get(this.endpoint, {
        params: {
          [this.searchParam]: search,
          page: this.page,
          ...this.externalParams
        }
      })
      .then(response => {
        const { data = [] } = response || {}
        this.results = this.results.concat(
          this.responseParser ? this.responseParser(data) : data
        )

        this.has_next_page = data.length > 0
      })
      .catch(error => {
        this.error = error
      })
      .finally(() => {
        this.searching = false
      })
  }

  @computed get hasResults() {
    return this.results && this.results.some(({ items }) => items.length > 0)
  }

  @computed get notSearched() {
    return this.results === null
  }

  @computed get totalResults() {
    return this.hasResults
      ? this.results.reduce((acc, current) => acc + current.items.length, 0)
      : 0
  }

  @computed get flatResults() {
    return this.results.reduce((acc, current) => acc.concat(current.items), [])
  }

  @computed get params() {
    return { search: this.selected.name }
  }

  @action setSelected(selected) {
    this.selected = selected
  }
}
