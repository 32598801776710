import { setHeaders, removeHeaders } from '@api'
import { makeObservable, observable } from 'mobx'
import { IS_LOCAL } from '@constants'
import Auth from '../Auth'

export default class Authentication {
  @observable error = null
  @observable retry_tokens = 0

  constructor(rootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  refreshAuthentication = async () => {
    const access_token = Auth.getAccessToken()
    const refresh_token = Auth.getRefreshToken()

    if (IS_LOCAL) {
      if (!access_token || !refresh_token) {
        const success = await Auth.copyStorage()
        if (success) setHeaders()
      }

      if (!Auth.isAuthenticated()) this.signOut()
    }
  }

  isAuthenticated = () => Auth.isAuthenticated()

  signOut = () => {
    removeHeaders()
    Auth.signOut()
  }

  cleanStorage() {
    removeHeaders()
    Auth.cleanStorage()
  }
}
