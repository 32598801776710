import i18n from '../../i18n'

export const getSubmitSurveyPage = () => ({
  codename: 'submit_survey',
  title: i18n.t('Submit Survey'),
  description: i18n.t(
    'You are about to submit your survey. This step cannot be undone.'
  ),
  backButton: i18n.t('No, I’d like to edit my responses'),
  nextButton: i18n.t('Submit'),
  isRequired: true
})
