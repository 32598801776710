import { computed, action, makeObservable } from 'mobx'
import { api } from '@api'
import SurveyState from '../index'

class Preview extends SurveyState {
  constructor(rootStore) {
    super(rootStore)
    makeObservable(this)
  }

  @computed get isPreview() {
    return true
  }

  @computed get showBanner() {
    return false
  }

  @action getSurvey = async () => {
    const path = `/preview/survey/${this.codename}/`
    const params = {
      relationship: this.relationship,
      audience: this.audience,
      language: this.language_code,
      exclude: this.excludeModules
    }

    return api
      .get(path, { params })
      .then(response => {
        const { data, info } = response.data
        this.initSurvey(data)
        this.info = info || {}
      })
      .catch(error => {
        this.error = error.message
        return false
      })
  }

  @action getModule = async () => {
    const path = `/preview/module/${this.codename}/`
    const params = {
      audience: this.audience,
      language: this.language_code,
      relationship: this.relationship
    }

    return api
      .get(path, { params })
      .then(response => {
        this.initSurvey(response.data)
        const module = response.data[0]
        this.info = { audience: module?.audience_name, name: module?.title }
      })
      .catch(error => {
        this.error = error.message
        return false
      })
  }

  @action getSteps = async () => {
    this.filters.survey ? await this.getSurvey() : await this.getModule()
    this.changePreviewMode(this.filters.mode)
  }

  @action async changeLanguage(language) {
    this.changing_language = true
    this.setLanguage(language)
    const answersSaved = this.answers
    await this.getSteps()
    this.setAnswers(answersSaved)
    this.changing_language = false
  }

  @action async load() {
    this.loading = true
    this.setCurrentStep(0)
    await this.getSteps()
    this.loading = false
  }

  @action getFiltersOptions = () => this.filters.getOptions()

  @action getModuleLanguages = async () =>
    api
      .get(`/preview/module/${this.codename}/languages/`)
      .then(response => this.setLanguages(response.data))
      .catch(error => {
        this.error = error
      })

  @action getSurveyLanguages = async () =>
    api
      .get(`/preview/survey/${this.codename}/languages/`)
      .then(response => this.setLanguages(response.data))
      .catch(error => {
        this.error = error
      })

  @action getLanguages = async () =>
    api
      .get('/organizations/languages/', { params: { is_active: true } })
      .then(response => this.setLanguages(response.data))
      .catch(error => {
        this.error = error
      })

  @action changePreviewMode(value) {
    this.filters.mode = value || this.filters.modes[0].value
    this.setSteps(
      this.filters.showAll ? this.survey.stepsAllMode : this.survey.steps
    )
    this.survey.resetAnswers()
  }

  @action submitSurvey() {}
}

export default Preview
