import { makeObservable, observable, override, action } from 'mobx'
import { EMPTY_FIELD } from '@constants'
import MultipleRating from '../../models/Question/MultipleRating'
import Question from './index'

const PERFECT_SCORE = 5
const PERFECT_SCORE_LIMIT = 5

export default class SurveyMultipleRating extends Question(MultipleRating) {
  @observable perfect_score_counter = 0

  constructor(previewStore, props = {}) {
    super(previewStore, props)
    makeObservable(this)
  }

  @override get hasValidAnswer() {
    return this.answer && Object.keys(this.answer).length === this.items.length
  }

  @override get answerParsed() {
    return this.items.map(({ text, value }) => {
      const option = this.answer
        ? this.rateValues.find(rate => rate.value === this.answer[value])
        : null
      const answerValue = option ? option.text : EMPTY_FIELD
      return `${text} (${answerValue})`
    })
  }

  @override get hasPerfectScore() {
    return this.perfect_score_counter >= PERFECT_SCORE_LIMIT
  }

  @action perfectScoreCounter(value) {
    if (value === PERFECT_SCORE) {
      this.perfect_score_counter += 1
    } else {
      this.perfect_score_counter = 0
    }
  }
}
