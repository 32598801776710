import { action, computed, observable, makeObservable, override } from 'mobx'
import { api, variantApi, questionsApi, updateVariantApi } from '@api'
import { showNotification } from '@utils'
import cloneDeep from 'lodash/cloneDeep'
import { QUESTIONS_MODELS } from './Question/constants'
import Module from '../models/Module'
import i18n from '../../i18n'
import EditorMultipleSelection from './Question/MultipleSelection'
import QuestionLibrary from './QuestionLibrary'
import Question from './Question'

export default class ModuleEditor extends Module {
  @observable currentQuestion = null
  @observable questionLibrary = new QuestionLibrary(this)
  @observable creating = false
  @observable previewMode = 'desktop'

  constructor(rootStore, props) {
    super(props)
    this.rootStore = rootStore
    makeObservable(this)
  }

  @computed get languageName() {
    return this.language ? this.language.name : ''
  }

  @computed get notFound() {
    return this.error && this.error.response && this.error.response.status === 404
  }

  @computed get questionsGroupped() {
    if (this.questions.length === 0) return this.questions

    const groups = [[]]
    let lastGroup = this.questions[0].order_group

    for (let i = 0; i < this.questions.length; i += 1) {
      const current = this.questions[i]
      if (current.order_group === lastGroup) {
        groups[groups.length - 1].push(current)
      } else {
        lastGroup = current.order_group
        groups.push([current])
      }
    }

    groups.forEach((group, groupIndex) =>
      group.forEach((question, questionIndex) => {
        const index = groupIndex + 1
        const subIndex = questionIndex > 0 ? `.${questionIndex}` : ''
        question.number = `${index}${subIndex}`
      })
    )

    return groups
  }

  @action setParams = async (props = {}) => {
    updateVariantApi(props)
    await this.getLanguage(props.language)
  }

  @action getLanguage = async code => {
    this.language_code = code || 'en'
    await i18n.changeLanguage(this.language_code)
  }

  @action resetCurrentQuestion = async () =>
    this.getQuestion(this.currentQuestion.id)

  @action setCurrentQuestion = question => {
    this.currentQuestion = question
  }

  @action changeQuestionType = (questionType = {}) => {
    const { codename, ui_representation } = questionType
    const newQuestion = this.createQuestion(ui_representation, {
      codename: this.currentQuestion.codename,
      description: this.currentQuestion.description,
      id: this.currentQuestion.id,
      order: this.currentQuestion.order,
      question_type: codename,
      ui_representation: ui_representation,
      title: this.currentQuestion.title,
      version: this.currentQuestion.version,
      original_question: this.currentQuestion.original_question
    })
    this.setCurrentQuestion(newQuestion)
  }

  @action setLoadingQuestion = value => {
    this.loading_question = value
  }

  @action updateQuestionOnList = ({ id, ...values }) => {
    const KEYS = [
      'title',
      'description',
      'status',
      'question_type_display',
      'editor_config'
    ]
    const question = this.questions.find(question => question.id === id)

    KEYS.forEach(key => {
      question[key] = values[key]
    })
  }

  @action setModuleHeader = ({ title, description }) => {
    this.title = title
    this.description = description
  }

  @action getQuestion = async id => {
    this.setLoadingQuestion(true)
    return questionsApi
      .get(`/${id}/`)
      .then(({ data }) => {
        const question = this.createQuestion(
          data.ui_representation || data.question_type,
          cloneDeep(data)
        )
        this.setCurrentQuestion(question)
      })
      .catch(error => {
        this.error = error
      })
      .finally(() => {
        this.setLoadingQuestion(false)
      })
  }

  @action deleteQuestion = questionId => {
    const removingCurrent = this.currentQuestion.id === questionId
    const questionIndex = this.questions.findIndex(({ id }) => id === questionId)

    return questionsApi
      .delete(`/${questionId}/`)
      .then(async () => {
        showNotification('Question deleted')
        await this.getQuestions()
        if (removingCurrent) this.resolveCurrent(questionIndex)
      })
      .catch(error => showNotification(error.message, 'negative'))
  }

  @action resolveCurrent(currentIndex) {
    if (!this.questions.length) {
      this.setCurrentQuestion(null)
    } else {
      const index =
        currentIndex < this.questions.length ? currentIndex : currentIndex - 1
      this.getQuestion(this.questions[index].id)
    }
  }

  @action addNewQuestion = () => {
    const multipleChoice = new EditorMultipleSelection(this)
    const body = {
      title: multipleChoice.title,
      question_type: multipleChoice.type,
      options: multipleChoice.optionsParams
    }
    return variantApi
      .post('/questions/', body)
      .then(response => {
        this.setQuestions([...this.questions, response.data])
        this.getQuestion(response.data.id)
      })
      .catch(error => {
        this.error = error
      })
  }

  @action async createFromQuestionLibrary(codename) {
    this.creating = true
    return variantApi
      .post(`/questions/library/${codename}/`, { audience: this.audience })
      .then(async response => {
        this.error = null
        await this.getQuestions()
        return this.getQuestion(response.data.id)
      })
      .catch(error => {
        this.error = error
        showNotification(
          "We've encountered a network or system issue. Please try again later.",
          'negative'
        )
      })
      .finally(() => {
        this.creating = false
      })
  }

  @action orderQuestions = (drag_index, hover_index) => {
    const newList = [...this.questionsGroupped]
    const elementA = newList[drag_index]
    const elementB = newList[hover_index]
    newList[drag_index] = elementB
    newList[hover_index] = elementA
    this.questions = newList.reduce((acc, current) => [...acc, ...current], [])
  }

  @action submitOrderQuestions = () =>
    variantApi
      .post(
        '/order-questions/',
        this.questions.map(({ codename, version }, index) => ({
          codename,
          version,
          order: index + 1
        }))
      )
      .catch(error => showNotification(error.message, 'negative'))

  @action get = async () =>
    variantApi
      .get('/module/')
      .then(response => this.init(response.data))
      .catch(error => {
        this.error = error
      })

  @action getFirstQuestion = async () =>
    variantApi
      .get('/first-question/')
      .then(({ data }) => {
        const question = this.createQuestion(
          data.ui_representation || data.question_type,
          cloneDeep(data)
        )
        this.setCurrentQuestion(question)
      })
      .catch(error => {
        this.error = error
      })

  @action getQuestions = async () =>
    variantApi
      .get('/questions/')
      .then(response => this.setQuestions(response.data))
      .catch(error => {
        this.error = error
      })

  @action duplicateQuestion(questionId) {
    return variantApi
      .post(`/questions/${questionId}/duplicate/`)
      .then(() => this.getQuestions())
      .catch(error => showNotification(error.message, 'negative'))
  }

  @override setQuestions(questions) {
    const questionsParsed = questions.map(
      ({ can_add_to_library, can_update_to_library, ...question }) => {
        const QuestionClass = Question()

        return new QuestionClass(this, {
          editor_config: { can_add_to_library, can_update_to_library },
          ...question
        })
      }
    )

    super.setQuestions(questionsParsed)
  }

  @action updateModule = async ({ title, description }) => {
    this.setModuleHeader({ title, description })
    return api
      .put(`/modules/${this.id}/`, { title, description })
      .catch(() => showNotification('Try again later', 'negative'))
  }

  @action getConfiguration = async () => {
    return api
      .get(`/entities/${this.source}/editor-configuration/`)
      .then(response => this.setConfiguration(response.data.configuration))
      .catch(error => {
        this.error = error
      })
  }

  @action getVarOptions = async () => {
    return variantApi
      .get('/variables/')
      .then(response => this.setOptions(response.data.variables))
      .catch(error => {
        this.error = error
      })
  }

  @action setPreviewMode(value) {
    this.previewMode = value
  }

  @action getPreviewButton() {
    return variantApi
      .get('/preview-button/')
      .then(response => {
        this.preview_button = response.data
      })
      .catch(error => {
        this.error = error
      })
  }

  @computed get isMobileMode() {
    return this.previewMode === 'mobile'
  }

  @override get isResponsive() {
    return this.isMobileMode || super.isResponsive
  }

  @computed get isEditable() {
    return !this.isResponsive
  }

  createQuestion = (ui_representation, params) =>
    new QUESTIONS_MODELS[ui_representation](this, params)
}
