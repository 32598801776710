import { observable, computed, action } from 'mobx'

export default class Profile {
  @observable id = ''
  @observable first_name = ''
  @observable last_name = ''
  @observable email = ''
  @observable mobile_phone = ''
  @observable avatar = ''
  @observable display_name = ''
  @observable is_email_verified = false
  @observable error = null

  constructor(props) {
    this.id = props.id || ''
    this.first_name = props.first_name || ''
    this.last_name = props.last_name || ''
    this.email = props.email || ''
    this.mobile_phone = props.mobile_phone || ''
    this.avatar = props.avatar || ''
    this.display_name = props.display_name || ''
    this.is_email_verified = props.is_email_verified
  }

  @action init(props = {}) {
    this.id = props.id || ''
    this.first_name = props.first_name || ''
    this.last_name = props.last_name || ''
    this.email = props.email || ''
    this.mobile_phone = props.mobile_phone || ''
    this.avatar = props.avatar || ''
    this.display_name = props.display_name || ''
    this.is_email_verified = props.is_email_verified
  }

  @action reset() {
    this.id = ''
    this.first_name = ''
    this.last_name = ''
    this.email = ''
    this.mobile_phone = ''
    this.avatar = ''
    this.display_name = ''
    this.is_email_verified = false
  }

  @computed get hasAvatar() {
    return !!this.avatar
  }

  @computed get fullname() {
    return this.display_name
  }

  @computed get fullNameOrEmail() {
    return this.fullname || this.email
  }

  @computed get initials() {
    if (this.first_name && this.last_name) {
      return this.first_name.substring(0, 1) + this.last_name.substring(0, 1)
    }

    if (this.first_name || this.last_name) {
      const name = this.first_name || this.last_name
      name.substring(0, 2)
    }

    return this.email.substring(0, 2)
  }
}
