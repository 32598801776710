import { action, computed } from 'mobx'
import Question from './index'

export default class Counter extends Question {
  @computed get items() {
    return this.options.rows
  }

  @computed get desktopItems() {
    return this.desktop_options.rows
  }

  @action setItems(rows) {
    this.desktop_options.setRows(rows)
  }
}
