import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import ChainedBackend from 'i18next-chained-backend'
import resourcesToBackend from 'i18next-resources-to-backend'
import { BASE_URL_API } from '@constants'
import Auth from './Auth'

const localResources = (lng, _, clb) => clb(null, lng === 'en' ? { key: 'key' } : {})

i18n
  .use(ChainedBackend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // resources,
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
      prefix: '{',
      suffix: '}'
    },
    react: {
      transKeepBasicHtmlNodesFor: []
    },
    backend: {
      backends: [resourcesToBackend(localResources), Backend],
      backendOptions: [
        {},
        {
          loadPath: `${BASE_URL_API}/translations/survey-ui/?lang={lng}`,
          customHeaders: () => {
            const token = Auth.getAccessToken()
            return { authorization: token ? `Bearer ${token}` : null }
          }
        }
      ]
    }
  })

export default i18n
