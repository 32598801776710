import React from 'react'
import { Divider as SemanticDivider } from 'semantic-ui-react'

const Divider = ({
  small = false,
  double = false,
  title = false,
  hidden = false,
  section = false,
  soft = false,
  custom = {},
  className = '',
  tiny = false,
  huge = false
}) => {
  let classNames = className
  if (double) {
    classNames += ' double'
  }
  if (small) {
    classNames += ' small'
  }
  if (title) {
    classNames += ' title'
  }
  if (tiny) {
    classNames += ' tiny'
  }
  if (soft) {
    classNames += ' soft'
  }
  if (huge) {
    classNames += ' huge'
  }
  classNames = classNames.trim()
  return (
    <SemanticDivider
      className={classNames}
      hidden={hidden}
      section={section}
      style={custom}
    />
  )
}

export default Divider
