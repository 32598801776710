import React, { Component, Suspense, lazy } from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { inject, observer, Provider } from 'mobx-react'
import Loader from '@ui/Loader'
import Toast from '@ui/Toast'
import Layout from '@ui/Layouts/Public'

const Survey = lazy(() => import(/* webpackChunkName: "Survey" */ '@screens/Survey'))

const Editor = lazy(() => import(/* webpackChunkName: "Editor" */ '@screens/Editor'))

const Preview = lazy(() =>
  import(/* webpackChunkName: "Preview" */ '@screens/Preview')
)

const Error = lazy(() => import(/* webpackChunkName: "Error" */ '@screens/Error'))

const Redirector = lazy(() =>
  import(/* webpackChunkName: "Redirector" */ './../../Redirector')
)
@withRouter
@inject('authentication', 'previewSurvey', 'userSurvey', 'appState')
@observer
class App extends Component {
  componentDidMount() {
    window.addEventListener('resize', this.setWindowWidth)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setWindowWidth)
  }

  setWindowWidth = () => this.props.appState.setWindowWidth(window.innerWidth)

  render() {
    const { userSurvey, previewSurvey } = this.props

    return (
      <Suspense className='height full' fallback={<Loader />}>
        <Toast />
        <Switch>
          <Redirect
            from='/:url*(/+)'
            to={{ ...location, pathname: location.pathname.slice(0, -1) }}
          />
          <Route path='/editor/:codename' component={Editor} />
          <Route
            path='/preview'
            render={() => (
              <Provider surveyState={previewSurvey}>
                <Preview />
              </Provider>
            )}
          />
          <Route
            exact
            path='/survey/:id'
            render={() => (
              <Provider surveyState={userSurvey}>
                <Survey />
              </Provider>
            )}
          />
          <Route
            path='/error/:status?'
            render={() => (
              <Layout>
                <Layout.Main>
                  <Error />
                </Layout.Main>
              </Layout>
            )}
          />
          <Route exact path='/redirect' component={Redirector} />

          <Redirect to='/error' />
        </Switch>
      </Suspense>
    )
  }
}

export default App
