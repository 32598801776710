import { action, computed, makeObservable, override } from 'mobx'
import Counter from '../../models/Question/Counter'
import Question from './index'

export default class EditorCounter extends Question(Counter) {
  constructor(moduleStore, props = {}) {
    super(moduleStore, props)
    makeObservable(this)
    this.init(props)
  }

  @override get optionsParams() {
    return {
      ...super.optionsParams,
      rows: this.desktopItems
    }
  }

  @computed get defaultItem() {
    return { text: 'Unnamed Option', value: `row_${new Date().getTime()}` }
  }

  @computed get canRemove() {
    return this.items.length > 1
  }

  @action init() {
    if (this.items.length === 0) this.setItems([this.defaultItem])
  }

  @computed get showAdd() {
    return this.buttonsEditable
  }

  @computed get addText() {
    return 'Add another row'
  }
}
