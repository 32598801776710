import axios from 'axios'
import * as Sentry from '@sentry/browser'
import { BASE_URL_API, BASE_URL_API_OLD, BASE_URL_AUTH_API } from './constants'
import Auth from './Auth'

if (!Promise.prototype.finally) {
  require('promise.prototype.finally').shim()
}

export const REDIRECTING = 'redirecting'

export const api = createApi({ baseURL: BASE_URL_API })

export const apiOld = createApi({ baseURL: BASE_URL_API_OLD })

export const authApi = createApi({ baseURL: BASE_URL_AUTH_API })

export const variantApi = createApi({
  baseURL: `${BASE_URL_API}/components/modules`
})

export const surveyApi = createApi({ baseURL: `${BASE_URL_API}/survey/` })

export const questionsApi = createApi({ baseURL: `${BASE_URL_API}/questions` })

export const competenciesApi = createApi({ baseURL: `${BASE_URL_API}/competencies` })

export const questionLibraryApi = createApi({
  baseURL: `${BASE_URL_API}/question-library`
})

const APIS = [
  api,
  apiOld,
  variantApi,
  surveyApi,
  questionsApi,
  competenciesApi,
  questionLibraryApi
]

export function setHeaders() {
  APIS.forEach(instance => setHeadersToInstance(instance))
}

export function removeHeaders() {
  APIS.forEach(instance => removeHeadersToInstance(instance))
}

function removeHeadersToInstance(instance) {
  delete instance.defaults.headers.Authorization
}

function setHeadersToInstance(instance) {
  instance.defaults.headers.Authorization = Auth.getAuthorizationToken()
}

function updateRequestHeaders(config) {
  config.headers.Authorization = Auth.getAuthorizationToken()
}

function createApi({ baseURL }) {
  const token = Auth.getAuthorizationToken()
  const instance = axios.create({
    baseURL,
    withCredentials: true,
    timeout: 30000,
    headers: token ? { Authorization: token } : {}
  })

  instance.interceptors.response.use(
    response => {
      if (response.headers.location) {
        location.replace(response.headers.location)
        response.message = REDIRECTING
      }
      return response
    },
    async error => {
      if (!(error && error.response)) {
        // CASE: Getting a response from the request since the browser
        // received a 401 unauthorized response
        return Promise.reject(error)
      }

      if (error.response.headers.location) {
        location.replace(error.response.headers.location)
        error.message = REDIRECTING
        return Promise.reject(error)
      }

      if (error.response.status === 401) {
        await Auth.refreshToken()
        setHeaders()
        updateRequestHeaders(error.config)
        if (!Auth.error && !error.config.__isRetryRequest) {
          error.config.__isRetryRequest = true
          return axios(error.config)
        }
      }

      if (error.response.status === 403) {
        Sentry.captureException(error)
        window.location = '/error'
      }

      return Promise.reject(error)
    }
  )

  return instance
}

export function updateVariantApi({ codename, ...params }) {
  variantApi.defaults.baseURL = `${BASE_URL_API}/components/modules/${codename}`
  variantApi.defaults.params = params
}

export function updateSurveyApi(id, params) {
  surveyApi.defaults.baseURL = `${BASE_URL_API}/survey/${id}/`
  surveyApi.defaults.params = params
}
