import { action, computed, makeObservable, observable } from 'mobx'
import { api } from '@api'
import ScaleWording from './ScaleWording'

export default class ScalesWording {
  @observable list = []
  @observable loading = true
  @observable default_options = [
    { text: '1 - Okay', value: 1 },
    { text: '2 - Good', value: 2 },
    { text: '3 - Really Good', value: 3 },
    { text: '4 - Great', value: 4 },
    { text: '5 - Best', value: 5 }
  ]

  constructor(list = []) {
    makeObservable(this)
    this.list = list.map(scale => new ScaleWording(scale))
  }

  @action set(list = []) {
    this.list = list.map(scale => new ScaleWording(scale))
  }

  @action async get(params = {}) {
    return api
      .get('/rating-scales/', { params })
      .then(response => {
        this.set(response.data)
        this.loading = false
      })
      .catch(error => {
        this.error = error
        this.loading = false
      })
  }

  @computed get options() {
    return this.list.map(({ name, codename, scale }) => ({
      text: name,
      value: codename,
      scale
    }))
  }

  @computed get isEmpty() {
    return this.list.length === 0
  }

  @computed get defaultScale() {
    if (this.options.length === 0) return { scale: this.default_options }
    const defaultScale = this.list.find(({ is_default }) => is_default)
    return defaultScale || this.list[0] || {}
  }
}
