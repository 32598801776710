import React from 'react'
import Dropdown from '@ui/Dropdown'
import cn from 'classnames'
import Flag from './Flag'

function LanguageDropdown({
  options,
  selected = null,
  onChange,
  loading,
  disabled,
  placeholder,
  color,
  className,
  sm
}) {
  const optionsParsed = options.map(({ icon, codename, name }) => ({
    key: codename,
    icon: <Flag codename={icon} size={16} />,
    text: name,
    value: codename
  }))

  const selectedIcon = options.find(({ codename }) => codename === selected)?.icon

  const trigger = sm ? (
    <Flag className='trigger' codename={selectedIcon} bordered />
  ) : undefined

  return (
    optionsParsed.length > 0 && (
      <Dropdown
        options={optionsParsed}
        value={selected}
        onChange={onChange}
        className={cn('language-selector', color, className, {
          sm,
          selection: !sm,
          'settings-dropdown': !sm
        })}
        disabled={disabled || loading}
        loading={loading}
        placeholder={placeholder}
        direction={sm ? 'left' : 'right'}
        trigger={trigger}
      />
    )
  )
}

export default LanguageDropdown
