import { action, computed, observable } from 'mobx'

export default class Column {
  @observable id = ''
  @observable name = ''
  @observable is_active = true
  @observable is_editable = false
  @observable order = 0
  @observable grow = 1
  @observable width = undefined
  @observable maxWidth = undefined
  @observable minWidth = undefined
  @observable sortable = false
  @observable center = false
  @observable right = false
  @observable type = ''
  @observable wrap = false
  @observable extras = {}
  @observable hide_on_responsive = false
  @observable check_disabled = false
  @observable allowOverflow = false
  @observable background_color = ''
  @observable text_color = ''
  @observable selector = null
  @observable icon = null
  @observable alignItems = 'flex-start'
  @observable fixed = false

  constructor(columnsManager, props) {
    this.columnsManager = columnsManager
    this.id = props.field_name
    this.name = props.name
    this.is_active = props.is_active !== false
    this.order = props.order
    this.sortable = props.sortable || this.sortable
    this.type = props.type
    this.extras = props.extras || {}
    this.center = props.center || this.center
    this.right = props.right || this.right
    this.grow = props.grow || this.grow
    this.is_editable = props.is_editable
    this.wrap = props.wrap || this.wrap
    this.width = props.width || this.width
    this.maxWidth = props.max_width || this.maxWidth
    this.minWidth = props.min_width || this.minWidth
    this.hide_on_responsive = props.hide_on_responsive || this.hide_on_responsive
    this.check_disabled = props.check_disabled || this.check_disabled
    this.allowOverflow = props.allow_overflow || this.allow_overflow
    this.selector = props.selector || this.defaultSelector
    this.background_color = props.background_color || this.background_color
    this.text_color = props.text_color || this.text_color
    this.fixed = props.fixed || this.fixed
  }

  @action toggleActive() {
    this.is_active = !this.is_active
  }

  @action setActive(value) {
    this.is_active = value
  }

  @action setOrder(value) {
    this.order = value
  }

  @computed get params() {
    return {
      field_name: this.id,
      name: this.name,
      is_active: this.is_active,
      order: this.order,
      sortable: this.sortable,
      type: this.type,
      extras: this.extras,
      is_editable: this.is_editable
    }
  }

  isDisabled = row => this.check_disabled && row.disabled

  defaultSelector = (row = {}) => row[this.id]

  @computed get fixedsWidth() {
    const { fixed } = this.columnsManager
    const index = fixed.findIndex(column => column.id === this.id)
    let acc = 0

    for (let i = index + 1; i < fixed.length; i++) {
      acc += fixed[i].width
    }

    return acc
  }

  @computed get isFirstFixed() {
    const { fixed } = this.columnsManager
    const index = fixed.findIndex(column => column.id === this.id)
    return index === 0
  }
}
