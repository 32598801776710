import { action, observable, computed, makeObservable } from 'mobx'

export default class Languages {
  @observable list = []

  constructor(list = []) {
    makeObservable(this)
    this.list = list
  }

  @action set(languages) {
    this.list = languages
  }

  @computed get options() {
    return this.list.map(({ name, codename }) => ({ value: codename, text: name }))
  }

  get = languageCode => {
    const language = this.list.find(({ codename }) => codename === languageCode)
    return language || { codename: languageCode }
  }

  getIcon = languageCode => {
    const language = this.get(languageCode)
    return language.icon || ''
  }

  getLabel = languageCode => {
    const language = this.get(languageCode)
    return language.name || ''
  }
}
