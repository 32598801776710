import { makeObservable, override } from 'mobx'
import { EMPTY_FIELD } from '@constants'
import Counter from '../../models/Question/Counter'
import Question from './index'

export default class CounterSurvey extends Question(Counter) {
  constructor(previewStore, props = {}) {
    super(previewStore, props)
    makeObservable(this)
  }

  @override get hasValidAnswer() {
    return this.answer && Object.values(this.answer).some(value => value > 0)
  }

  @override get answerParsed() {
    return this.items.map(({ value, text }) => {
      const response = this.answer ? this.answer[value] : null
      return `${text} (${response || EMPTY_FIELD})`
    })
  }
}
