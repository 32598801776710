import { Component } from 'react'

class Content extends Component {
  render() {
    return this.props.children
  }
}

Content.displayName = 'Content'

export default Content
