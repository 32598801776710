import React, { Component } from 'react'
import { Grid, Cell } from 'styled-css-grid'
import { Container, Image } from 'semantic-ui-react'
import { Logo as CrosschqLogo } from '@ui/Logo'
import LanguageSelector from '@ui/LanguageSelector'
import Footer from '../Footer'
import Main from '../Pieces/Main'

export default class Layout extends Component {
  static Main = Main
  render() {
    const { children, debug = false, brandLogo, showLanguageSelector } = this.props

    const styles = {
      topnav: {
        display: 'flex',
        margin: '15px 20px 20px',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 'auto'
      },
      main: {},
      footer: {},
      wrapper: {
        minHeight: '100vh'
      }
    }
    if (debug) {
      styles.topnav.backgroundColor = '#FFAAAA'
      styles.main.backgroundColor = '#AAFFAA'
      styles.footer.backgroundColor = '#AAAAFF'
    }

    const logo = brandLogo ? (
      <div className='logo-wrapper'>
        <Image src={brandLogo} />
      </div>
    ) : (
      <CrosschqLogo />
    )

    return (
      <Grid
        columns={'1fr'}
        rows={'auto 1fr auto'}
        areas={['topnav', 'main', 'footer']}
        columnGap={'0'}
        rowGap={'0'}
        style={styles.wrapper}
      >
        <Cell area='topnav' style={styles.topnav}>
          <Container>
            {logo}
            {showLanguageSelector && <LanguageSelector sm />}
          </Container>
        </Cell>
        <Cell area='main' style={styles.main}>
          <div className='full height padding x20'>
            {React.Children.map(children, child => {
              if (child.type.displayName === 'Main') {
                return React.cloneElement(child)
              }
            })}
          </div>
        </Cell>
        <Cell area='footer' style={styles.footer}>
          <Footer showCrosschqLogo={brandLogo} />
        </Cell>
      </Grid>
    )
  }
}
