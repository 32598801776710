import { action, computed, makeObservable, observable, override } from 'mobx'
import stores from '@stores'
import QuestionModel from '@stores/models/Question'
import { questionsApi } from '@api'
import { showNotification } from '@utils'

export default function Question(parentClass = QuestionModel) {
  return class QuestionEditor extends parentClass {
    @observable error = null
    @observable saving = false
    @observable editor_config = {}
    @observable addingItem = false
    @observable order_group = null
    @observable can_delete = true
    @observable can_order = true
    @observable can_actions = true
    @observable skip_follow_up_question = false
    @observable number = 0
    @observable autoScroll = 0

    constructor(moduleStore, props = {}) {
      super(props)
      makeObservable(this)
      this.moduleStore = moduleStore
      this.editor_config = props.editor_config || this.editor_config
      this.order_group = props.order_group || this.id
      this.can_delete = 'can_delete' in props ? props.can_delete : true
      this.can_order = 'can_order' in props ? props.can_order : true
      this.can_actions = 'can_actions' in props ? props.can_actions : true
      this.skip_follow_up_question =
        props.skip_follow_up_question || this.skip_follow_up_question
    }

    @override set(props) {
      super.set(props)
      this.editor_config = props.editor_config || this.editor_config
      this.order_group = props.order_group || this.id
      this.can_delete = 'can_delete' in props ? props.can_delete : true
      this.can_order = 'can_order' in props ? props.can_order : true
      this.can_actions = 'can_actions' in props ? props.can_actions : true
      this.skip_follow_up_question =
        props.skip_follow_up_question || this.skip_follow_up_question
    }

    @action update = (values = {}) => {
      Object.entries(values).forEach(([key, value]) => {
        this[key] = value
      })
    }

    @action addItem(item) {
      this.setItems([...this.items, item || this.defaultItem])
      this.autoScroll++
    }

    @action editItem({ id, text }) {
      this.addingItem = false
      this.setItems(
        this.items.map(item => (item.value === id ? { ...item, text } : item))
      )
    }

    @action removeItem(id) {
      this.setItems(this.items.filter(({ value }) => value !== id))
    }

    @action sortItems({ dragIndex, hoverIndex }) {
      const newItems = [...this.items]
      const elementA = { ...newItems[dragIndex] }
      const elementB = { ...newItems[hoverIndex] }
      newItems[dragIndex] = elementB
      newItems[hoverIndex] = elementA
      this.setItems(newItems)
    }

    @action updateFlag = (key, value) => {
      this.flags[key] = value
    }

    @action updateLayout = value => {
      this.desktop_options.setLayout(value)
    }

    @action save = async (isNew = false) => {
      this.saving = true
      return questionsApi
        .put(`/${this.id}/`, {
          ...this.params,
          skip_follow_up_question: this.showFollowUpToggle
            ? this.skip_follow_up_question
            : undefined,
          options: isNew ? {} : this.optionsParams || {}
        })
        .then(response => {
          this.error = null
          this.set(response.data)
          this.addingItem = false
        })
        .catch(error => {
          this.error = error
          showNotification(error.message, 'negative')
        })
        .finally(() => {
          this.saving = false
        })
    }

    @action setSkipFollowUpQuestion(value) {
      this.skip_follow_up_question = value
    }

    @action addOtherOption = value => {
      this.desktop_options.setOtherActive(value)
    }

    @action addNoneOption = value => {
      this.desktop_options.setNoneActive(value)
    }

    @action addDontKnowOption = value => {
      this.desktop_options.setDontKnowActive(value)
    }

    @action setMaxCount(value) {
      this.desktop_options.max_count = Number(value)
    }

    @computed get optionsParams() {
      const {
        raw,
        layout,
        dontKnowActive,
        otherActive,
        noneActive,
        min_count,
        max_count
      } = this.desktop_options
      const { validators = [] } = raw

      if (this.showMaxRequired) {
        const element = validators[0] || {}
        element.min_count = min_count
        element.max_count = max_count
      }

      return {
        ...this.desktop_options.raw,
        layout: this.hasOptionLayout ? layout : undefined,
        has_dont_know: this.showDontKnowOption ? dontKnowActive : undefined,
        has_other: this.showOtherOption ? otherActive : undefined,
        has_none: this.showNoneOption ? noneActive : undefined,
        validators
      }
    }

    @computed get typeInfo() {
      const { list } = stores.appState.questions_types
      const info = list.find(({ codename }) => codename === this.type)
      return info || {}
    }

    @computed get buttonsEditable() {
      if (this.isCondensed) return false

      return this.isFlagActive({ key: 'is_button_editable', defaultValue: true })
    }

    @computed get showCompetencyLibrary() {
      return this.isFlagActive({
        key: 'show_competency_library',
        defaultValue: false
      })
    }

    @computed get showDontKnowOption() {
      return this.isFlagActive({ key: 'show_dont_know_option', defaultValue: false })
    }

    @computed get showNoneOption() {
      return this.isFlagActive({ key: 'show_none_option', defaultValue: false })
    }

    @computed get showOtherOption() {
      return this.isFlagActive({ key: 'show_other_option', defaultValue: false })
    }

    @computed get showMaxRequired() {
      return this.isFlagActive({
        key: 'has_max_option_required',
        defaultValue: false
      })
    }

    @computed get hasOptionLayout() {
      return this.isFlagActive({ key: 'has_option_layout', defaultValue: false })
    }

    @computed get canAddToLibrary() {
      return this.isFlagActive({ key: 'can_add_to_library', defaultValue: false })
    }

    @computed get canUpdateToLibrary() {
      return this.isFlagActive({ key: 'can_update_to_library', defaultValue: false })
    }

    @computed get showRatingScale() {
      return this.isFlagActive({ key: 'show_rating_scale', defaultValue: false })
    }

    @computed get canEditTitle() {
      return this.isFlagActive({ key: 'can_edit_title', defaultValue: true })
    }

    @computed get canSkip() {
      return this.isFlagActive({ key: 'show_question_required', defaultValue: true })
    }

    @computed get showAudience() {
      return this.isFlagActive({ key: 'show_disabled_audience', defaultValue: true })
    }

    @computed get showQuestionType() {
      return this.isFlagActive({ key: 'show_question_type', defaultValue: true })
    }

    @computed get showRelationships() {
      return this.isFlagActive({ key: 'show_relationships', defaultValue: true })
    }

    @computed get showReportVisualization() {
      return this.isFlagActive({
        key: 'show_report_visualization',
        defaultValue: true
      })
    }

    @computed get showFollowUpToggle() {
      return this.isFlagActive({ key: 'show_follow_up_toggle', defaultValue: false })
    }

    @computed get skipFollowUpQuestion() {
      return this.isFlagActive({
        key: 'skip_follow_up_question',
        defaultValue: false
      })
    }

    @computed get canEditType() {
      return this.isFlagActive({
        key: 'can_edit_question_type',
        defaultValue: true
      })
    }

    @computed get canOrder() {
      return this.can_order
    }

    @computed get canDelete() {
      return this.can_delete
    }

    @computed get canActions() {
      return this.can_actions
    }

    isFlagActive = ({ key, defaultValue }) => {
      const { configurations } = this.moduleStore
      if (key in configurations && !configurations[key]) return false
      if (key in this.editor_config) return this.editor_config[key]
      if (key in this.typeInfo) return this.typeInfo[key]

      return defaultValue
    }

    @computed get canEditLibrary() {
      return (
        this.moduleStore.configurations.show_question_library &&
        (this.canAddToLibrary || this.canUpdateToLibrary)
      )
    }

    @computed get libraryButtonLabel() {
      return this.canUpdateToLibrary
        ? 'Update Question on my Library'
        : 'Add Question to my Library'
    }

    @computed get params() {
      return {
        title: this.title,
        description: this.description,
        question_type: this.type,
        is_required: this.isRequired,
        disabled_for_audience: this.isDisabledForAudience,
        relationships: this.relationships,
        report_visualization: this.report_visualization
      }
    }

    @computed get showSettingsButton() {
      return (
        this.showAudience ||
        this.showRelationshipSelect ||
        this.hasOptionLayout ||
        this.showMaxRequired
      )
    }
  }
}
