import React, { Component } from 'react'
import { Segment, Container } from 'semantic-ui-react'
import cn from 'classnames'

export default class View extends Component {
  render() {
    const { children, className } = this.props
    return (
      <Container className={cn('flex full height', className)}>
        <Segment padded='very'>{children}</Segment>
      </Container>
    )
  }
}
