import { observable, computed, action, makeObservable } from 'mobx'

class StepsManager {
  @observable steps = []
  @observable current_index = 0

  constructor() {
    makeObservable(this)
  }

  @computed get currentStep() {
    return this.current_index < this.steps.length
      ? this.steps[this.current_index]
      : null
  }

  @computed get currentStepIndex() {
    return this.current_index
  }

  @computed get previousStepIndex() {
    return this.current_index - 1
  }

  @computed get nextStepIndex() {
    return this.current_index + 1
  }

  @action setCurrentIndex(index) {
    this.current_index = index
  }

  @action previousStep() {
    if (this.previousStepIndex >= 0) {
      this.setCurrentIndex(this.previousStepIndex)
    }
  }

  @action nextStep = () => {
    if (this.nextStepIndex < this.steps.length) {
      this.setCurrentIndex(this.nextStepIndex)
    }
  }

  @action setSteps = steps => {
    this.steps = steps
  }
}

export default StepsManager
