import { Component } from 'react'

class Title extends Component {
  render() {
    return this.props.children
  }
}

Title.displayName = 'Title'

export default Title
