import { observable, action, override } from 'mobx'
import * as Sentry from '@sentry/browser'
import { IS_LOCAL } from '@constants'
import { showNotification } from '@utils'
import { apiOld, authApi } from '@api'
import OrganizationUser from './OrganizationUser'

export default class User extends OrganizationUser {
  @observable tw_id = ''
  @observable cq_id = ''
  @observable password = ''
  @observable token = ''
  @observable signature = ''

  constructor(rootStore) {
    super()
    this.rootStore = rootStore
  }

  @override init(props = {}) {
    super.init(props)
    this.tw_id = props.tw_id || ''
    this.cq_id = props.cq_id || ''
    this.weekly_email = props.weekly_email
    this.flags = props.flags || this.flags
  }

  @override reset() {
    super.reset()
    this.tw_id = ''
    this.cq_id = ''
    this.password = ''
    this.token = ''
  }

  async initTracking() {
    if (!IS_LOCAL) {
      Sentry.configureScope(scope => {
        scope.setTag('user.type', 'organization')
        scope.setUser({
          id: this.id,
          email: this.email,
          username: this.fullname
        })
      })
    }
  }

  @action async me() {
    return authApi
      .get('/me/')
      .then(response => {
        this.init(response.data)
        this.initTracking()
      })
      .catch(error => {
        this.error = error.message
      })
  }

  logout = () => this.rootStore.authentication.signOut()

  @action setSignature(signature) {
    this.signature = signature
  }

  @action interestedInProduct() {
    return apiOld
      .post(`/referrer/${this.signature}/lead_notification/`)
      .then(response => {
        if (response.status === 200) return true
        throw Error
      })
      .catch(error => {
        showNotification(
          "We've encountered a network or system issue. Please try again later.",
          'negative'
        )
        this.error = error.message
        return false
      })
  }
}
