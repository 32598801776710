import { QUESTIONS_TYPES } from '@constants'

import counter from '@images/QuestionsTypes/counter.svg'
import multipleChoice from '@images/QuestionsTypes/multiple-choice.svg'
import multipleRating from '@images/QuestionsTypes/multiple-rating.svg'
import multipleSelection from '@images/QuestionsTypes/multiple-selection.svg'
import openText from '@images/QuestionsTypes/open-text.svg'
import rating from '@images/QuestionsTypes/rating.svg'
import npsRating from '@images/QuestionsTypes/npsRating.svg'

export const ICONS = {
  [QUESTIONS_TYPES.MULTIPLE_CHOICE]: multipleChoice,
  [QUESTIONS_TYPES.COMMENT]: openText,
  [QUESTIONS_TYPES.OPEN_TEXT]: openText,
  [QUESTIONS_TYPES.MULTIPLE_SELECTION]: multipleSelection,
  [QUESTIONS_TYPES.RATING]: rating,
  [QUESTIONS_TYPES.MULTIPLE_RATING]: multipleRating,
  [QUESTIONS_TYPES.COUNTER]: counter,
  [QUESTIONS_TYPES.NPS_RATING]: npsRating
}
