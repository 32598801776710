import { observable } from 'mobx'

export default class QuestionType {
  @observable codename = ''
  @observable default_data = {}
  @observable description = ''
  @observable has_max_option_required = false
  @observable icon = ''
  @observable is_benchmarkable = false
  @observable is_button_editable = false
  @observable is_competency = false
  @observable is_demographic = false
  @observable is_nps = false
  @observable is_qoh = false
  @observable is_selectable = false
  @observable kb_url = ''
  @observable name = ''
  @observable show_competency_library = false
  @observable show_rating_scale = false
  @observable show_toggle_list = false
  @observable tooltip = ''
  @observable ui_representation = ''

  constructor(props = {}) {
    this.codename = props.codename || this.codename
    this.default_data = props.default_data || this.default_data
    this.description = props.description || this.description
    this.has_max_option_required =
      props.has_max_option_required || this.has_max_option_required
    this.icon = props.icon || this.icon
    this.is_benchmarkable = props.is_benchmarkable || this.is_benchmarkable
    this.is_button_editable = props.is_button_editable || this.is_button_editable
    this.is_competency = props.is_competency || this.is_competency
    this.is_demographic = props.is_demographic || this.is_demographic
    this.is_nps = props.is_nps || this.is_nps
    this.is_qoh = props.is_qoh || this.is_qoh
    this.is_selectable = props.is_selectable || this.is_selectable
    this.kb_url = props.kb_url || this.kb_url
    this.name = props.name || this.name
    this.show_competency_library = props.show_competency_library || false
    this.show_rating_scale = props.show_rating_scale || false
    this.show_toggle_list = props.show_toggle_list || this.show_toggle_list
    this.tooltip = props.tooltip || this.tooltip
    this.ui_representation = props.ui_representation || this.ui_representation
  }
}
