import { useState, useEffect } from 'react'

const useResponsive = breakpoint => {
  const [isResponsive, setIsResponsive] = useState(window.innerWidth < breakpoint)

  const validateIsResponsive = () => setIsResponsive(window.innerWidth < breakpoint)

  useEffect(() => validateIsResponsive(), [])

  useEffect(() => {
    window.addEventListener('resize', validateIsResponsive)
    return () => window.removeEventListener('resize', validateIsResponsive)
  }, [])

  return isResponsive
}

export default useResponsive
