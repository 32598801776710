import { override, makeObservable } from 'mobx'
import Rating from '../../models/Question/Rating'
import Question from './index'

export default class SurveyRating extends Question(Rating) {
  constructor(previewStore, props = {}) {
    super(previewStore, props)
    makeObservable(this)
  }

  @override get answerParsed() {
    const answer = this.choices.find(item => item.value === this.answer)
    return answer ? answer.text : ''
  }

  @override get hasValidAnswer() {
    return this.answer >= 0
  }
}
