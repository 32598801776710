import React, { useState } from 'react'
import { observer } from 'mobx-react'
import useStores from '@stores/useStores'
import useResponsive from '@hooks/useResponsive'
import i18n from '../../i18n'
import LanguageDropdown from '../LanguageDropdown'
import ModalSelector from './ModalSelector'

function LanguageSelector({ sm, className }) {
  const [loading, setLoading] = useState(false)
  const isResponsive = useResponsive(900)
  const { surveyState } = useStores()

  const handleChange = async (_, { value }) => {
    setLoading(true)
    await i18n.changeLanguage(value)
    await surveyState.changeLanguage(value)
    setLoading(false)
  }

  return (
    surveyState.languagesActive.length > 1 &&
    (isResponsive ? (
      <ModalSelector
        options={surveyState.languagesActive}
        selected={surveyState.language_code}
        onChange={handleChange}
        loading={loading}
        className={className}
      />
    ) : (
      <LanguageDropdown
        options={surveyState.languagesActive}
        selected={surveyState.language_code}
        onChange={handleChange}
        loading={loading}
        className={className}
        sm={sm}
      />
    ))
  )
}

export default observer(LanguageSelector)
