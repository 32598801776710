import { observable, computed, action, makeObservable } from 'mobx'
import queryString from 'query-string'
import { api } from '@api'
import i18n from '../../i18n'
import stores from '../index'

class Filters {
  @observable modules = []
  @observable surveys = []
  @observable modes = [
    { value: 'all', text: i18n.t('All Questions') },
    { value: 'one', text: i18n.t('One by One') }
  ]

  @observable extra_info_options = [
    { value: 'no', text: i18n.t('No') },
    { value: 'yes', text: i18n.t('Yes') }
  ]

  @observable relationship = ''
  @observable audience = ''
  @observable mode = ''
  @observable extra_info = ''
  @observable menu = 'on'
  @observable survey = ''
  @observable module = ''
  @observable exclude_modules = []

  constructor() {
    makeObservable(this)
  }

  @action set(props = {}) {
    Object.entries(props).map(([key, value]) => (this[key] = value))
  }

  @action initFilters(props = {}) {
    const { module, survey, ...filters } = props
    const isModuleValid =
      module && this.modules.some(({ codename }) => codename === module)
    const isSurveyValid =
      survey && this.surveys.some(({ codename }) => codename === survey)

    const moduleCodename = isModuleValid ? module : undefined
    const defaultSurvey = moduleCodename ? undefined : this.surveys[0].codename
    const surveyCodename = isSurveyValid ? survey : defaultSurvey

    this.set({
      mode: this.modes[0].value,
      extra_info: this.extra_info_options[0].value,
      survey: surveyCodename,
      module: moduleCodename,
      audience: this.audiences[0].value,
      relationship: this.relationships[0].value,
      ...filters
    })
  }

  @computed get codename() {
    return this.survey || this.module
  }

  @computed get codenameOption() {
    if (this.survey) return `${this.survey}-survey`
    if (this.module) return `${this.module}-module`
    return ''
  }

  @computed get menuAvailable() {
    return this.menu === 'on'
  }

  @computed get showAll() {
    return this.mode === 'all'
  }

  @computed get showExtraInfo() {
    return this.extra_info === 'yes'
  }

  @computed get queryParams() {
    return queryString.stringify({
      relationship: this.relationship,
      audience: this.audience,
      mode: this.mode,
      extra_info: this.extra_info,
      survey: this.survey,
      module: this.module
    })
  }

  @computed get relationships() {
    return stores.appState.relationships.map(({ name, codename }) => ({
      value: codename,
      text: name
    }))
  }

  @computed get audiences() {
    return stores.appState.audiences.map(({ name, codename }) => ({
      value: codename,
      text: name
    }))
  }

  @computed get surveysOptions() {
    return this.surveys.map(({ codename, name }) => ({
      value: `${codename}-survey`,
      text: `${name} (Survey)`
    }))
  }

  @computed get modulesOptions() {
    return this.modules.map(({ codename, name }) => ({
      value: `${codename}-module`,
      text: `${name} (Module)`
    }))
  }

  @computed get codenames() {
    return [...this.surveysOptions, ...this.modulesOptions]
  }

  @action setCodename = value => {
    const isSurvey = this.surveysOptions.some(survey => survey.value === value)
    if (isSurvey) {
      this.survey = this.surveys.find(
        survey => `${survey.codename}-survey` === value
      ).codename
      this.module = null
    } else {
      this.module = this.modules.find(
        survey => `${survey.codename}-module` === value
      ).codename
      this.survey = null
    }
  }

  @action getSurveys = async () =>
    api
      .get('/preview/survey/')
      .then(response => {
        this.surveys = response.data
      })
      .catch(error => error)

  @action getModules = async () =>
    api
      .get('/preview/module/')
      .then(response => {
        this.modules = response.data
      })
      .catch(error => error)

  @action getOptions = async () => {
    await Promise.all([
      this.getSurveys(),
      this.getModules(),
      stores.appState.getAudiences(),
      stores.appState.getRelationships()
    ])
  }
}

export default Filters
