import React, { useEffect, useRef, useState } from 'react'
import { Popup as SPopup } from 'semantic-ui-react'
import cn from 'classnames'
import { debounce } from 'lodash'
import Content from './Content'

function Popup({
  onOpen,
  onClose,
  notShowOnClick,
  showOnOverflow,
  trigger,
  content,
  children,
  triggerClassName,
  titled = false,
  className,
  disabled,
  ...props
}) {
  const triggerRef = useRef()
  const [open, setOpen] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [isOverflowed, setIsOverflowed] = useState(false)

  const setTooltipVisibility = () => {
    const { current } = triggerRef

    if (current) {
      setIsOverflowed(current.scrollWidth > current.clientWidth)
    }
  }

  const handleResize = () => setTooltipVisibility()

  const onResize = debounce(handleResize, 300)

  useEffect(() => {
    if (showOnOverflow) {
      setTooltipVisibility()
      window.addEventListener('resize', onResize)

      return () => window.removeEventListener('resize', onResize)
    }
  }, [showOnOverflow])

  const handleOpen = (event, data) => {
    const { type } = event
    // On responsive, make a click dispatch first the click event and
    // then the mouseover event
    setOpen(notShowOnClick ? !(type === 'click' || clicked) : true)
    setClicked(type === 'click')

    if (onOpen) onOpen(event, data)
  }

  const handleClose = (event, data) => {
    setOpen(false)
    if (onClose) onClose(event, data)
  }

  return (
    <SPopup
      open={open}
      trigger={
        // span required when the trigger is disabled
        <span ref={triggerRef} className={triggerClassName}>
          {trigger}
        </span>
      }
      size='tiny'
      position='top center'
      inverted
      basic
      {...props}
      className={cn(className, { titled })}
      onOpen={handleOpen}
      onClose={handleClose}
      disabled={disabled || (showOnOverflow && !isOverflowed)}
    >
      <Content tooltip={content || children} />
    </SPopup>
  )
}

export default Popup
