import { action, makeObservable, observable, override } from 'mobx'
import stores from '@stores'
import Rating from '../../models/Question/Rating'
import Question from './index'
import { parseRate } from './utils'

export default class EditorRating extends Question(Rating) {
  @observable scale_selected = null

  constructor(moduleStore, props = {}) {
    super(moduleStore, props)
    makeObservable(this)
    this.init(props)
  }

  @override get params() {
    return {
      ...super.params,
      rating_scale_codename: this.scale_selected
    }
  }

  @override get choices() {
    if (this.splitted) return super.choices

    const choices = super.choices.map(choice => {
      if (choice.id === this.dontKnowOption.id)
        return { ...choice, editable: false, inactive: true }

      const { prefix, text } = parseRate(choice.text)

      return {
        ...choice,
        editable: this.buttonsEditable,
        inactive: !this.buttonsEditable,
        text,
        prefix
      }
    })

    return choices
  }

  @action init(props) {
    this.setScaleSelected(props.rating_scale_codename)
    this.setRateValues(this.desktop_options.rateValues)
  }

  @override set(props) {
    super.set(props)
    this.setRateValues(this.desktop_options.rateValues)
  }

  @action setRateValues = newScale => {
    const isEmpty = !newScale || newScale.length === 0

    if (isEmpty) {
      const { scales_wording } = stores.appState
      if (this.showRatingScale) {
        const { codename, scale } = scales_wording.defaultScale
        this.setScaleSelected(codename)
        this.desktop_options.setRateValues(scale)
      } else {
        this.desktop_options.setRateValues(scales_wording.default_options)
      }
    } else {
      this.desktop_options.setRateValues(newScale)
    }
  }

  @action updateButton({ id, text }) {
    const rate = this.desktop_options.rateValues.find(rate => rate.value === id)
    rate.text = text
  }

  @action changeScaleWording({ codename, scale }) {
    this.setScaleSelected(codename)
    this.setRateValues(scale)
  }

  @action setScaleSelected(codename) {
    const { scales_wording } = stores.appState
    const { defaultScale } = scales_wording
    this.scale_selected = codename || defaultScale.codename
  }

  @override get dontKnowOption() {
    return {
      ...super.dontKnowOption,
      inactive: !stores.module.isResponsive
    }
  }
}
