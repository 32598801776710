import React, { useState } from 'react'
import { Modal, Header } from 'semantic-ui-react'
import cn from 'classnames'
import Flag from '../LanguageDropdown/Flag'
import './styles.scss'

const ModalSelector = ({ options, selected, onChange, className }) => {
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open)
  const handleSelect = codename => {
    onChange(null, { value: codename })
    toggle()
  }
  return (
    <Modal
      className='language-selector-modal'
      closeIcon
      open={open}
      trigger={
        <Flag
          codename={options.find(({ codename }) => codename === selected).icon}
          className={cn('trigger', className)}
          bordered
        />
      }
      size='tiny'
      onClose={toggle}
      onOpen={toggle}
    >
      <Header content='Select Language' />
      <Modal.Content className='modal-content'>
        {options.map(({ icon, codename, name }) => (
          <div
            className='language-option'
            key={codename}
            onClick={() => {
              handleSelect(codename)
            }}
          >
            <Flag codename={icon} bordered />
            {name}
          </div>
        ))}
      </Modal.Content>
    </Modal>
  )
}

export default ModalSelector
