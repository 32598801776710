import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'mobx-react'
import 'semantic-ui-css/semantic.min.css'
import '@crosschq/ui-components/lib/index.css'
import App from '@components/App'
import ErrorBoundary from '@components/ErrorBoundary'
import reportWebVitals from './reportWebVitals'
import stores from './stores'
import './axios-configuration'
import './styles/main.scss'
import './i18n'

ReactDOM.render(
  <Provider {...stores}>
    <Router>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Router>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
