import React, { Component } from 'react'
import { Icon as SIcon } from 'semantic-ui-react'

class Icon extends Component {
  render() {
    return <SIcon {...this.props} />
  }
}

Icon.displayName = 'Icon'

export default Icon
