import React, { Component } from 'react'
import { Dropdown as SDropdown } from 'semantic-ui-react'
import Popup from '@ui/Popup'

class Dropdown extends Component {
  state = { open: this.props.open }

  componentDidUpdate(prevProps) {
    const { open } = this.props
    if (prevProps.open !== open) this.setState({ open })
  }

  resolveOptions = () =>
    this.props.options.map(({ icon, value, text }) => ({
      text: this.makeLabel({ icon, text }),
      value
    }))

  makeLabel = ({ text, icon }) => (
    <div className='flex start no-wrap custom-item width full'>
      {icon}
      <Popup
        triggerClassName='truncate custom-item-text'
        trigger={text}
        showOnOverflow={this.state.open}
      >
        {text}
      </Popup>
    </div>
  )

  handleOpen = evt =>
    this.setState({ open: true }, () => {
      const { onOpen } = this.props
      if (onOpen) onOpen(evt)
    })

  handleClose = evt =>
    this.setState({ open: false }, () => {
      const { onClose } = this.props
      if (onClose) onClose(evt)
    })

  render() {
    const { options, selectOnBlur = false, ...props } = this.props
    const { open } = this.state

    return (
      <SDropdown
        options={this.resolveOptions()}
        selectOnBlur={selectOnBlur}
        {...props}
        open={open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
      />
    )
  }
}

export default Dropdown
