import App from './AppState'
import User from './Profile/User'
import Editor from './Editor'
import Module from './Editor/Module'
import Authentication from './Authentication'
import PreviewMode from './SurveyState/PreviewMode'
import UserMode from './SurveyState/UserMode'

class Stores {
  constructor() {
    this.appState = new App(this)
    this.user = new User(this)
    this.editor = new Editor(this)
    this.module = new Module(this)
    this.authentication = new Authentication(this)
    this.previewSurvey = new PreviewMode(this)
    this.userSurvey = new UserMode(this)
  }
}

const stores = new Stores()

export default stores
