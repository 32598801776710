import { action, computed, makeObservable } from 'mobx'
import { QUESTIONS_TYPES } from '@constants'
import Question from './index'

export default class MultipleChoice extends Question {
  constructor(props = {}) {
    super({ question_type: QUESTIONS_TYPES.MULTIPLE_CHOICE, ...props })
    makeObservable(this)
  }

  @computed get choices() {
    const choices = [...this.items]

    if (this.noneActive) {
      choices.push(this.noneOption)
    }

    if (this.otherActive) {
      choices.push(this.otherOption)
    }

    return choices
  }

  @computed get hasNotes() {
    return this.options.raw.has_notes
  }

  @computed get notesText() {
    return this.options.raw.notes_text
  }

  @computed get notesPlaceHolder() {
    return this.options.raw.notes_placeholder
  }

  @computed get otherPlaceholder() {
    return this.options.raw.other_placeholder
  }

  @computed get items() {
    return this.options.choices || this.defaultChoices || []
  }

  @computed get desktopItems() {
    return this.desktop_options.choices || this.defaultChoices || []
  }

  @action setItems(choices) {
    this.desktop_options.setChoices(choices)
  }
}
