import { action, observable, computed, makeObservable } from 'mobx'
import { api, apiOld, authApi } from '@api'
import { REPORT_VISUALIZATION_ICONS } from '@constants'
import { showNotification } from '@utils'
import Languages from './models/Languages'
import QuestionsTypes from './models/QuestionsTypes'
import ScalesWording from './models/ScalesWording'

export default class AppState {
  @observable questions_types = new QuestionsTypes()
  @observable scales_wording = new ScalesWording()
  @observable languages = new Languages()
  @observable report_visualization = []
  @observable relationships = []
  @observable audiences = []
  @observable error = null
  @observable helpList = []
  @observable organization = {}
  @observable windowWidth = window.innerWidth

  constructor(rootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @computed get reportVisualizationOptions() {
    return this.report_visualization.map(
      ({ name, codename, available_for, icon }) => ({
        text: name,
        value: codename,
        available_for,
        icon: REPORT_VISUALIZATION_ICONS[icon]
      })
    )
  }

  @action setLanguages = languages => this.languages.set(languages)

  @action setRelationships(relationships) {
    this.relationships = relationships
  }

  @action setReportVisualization = report_visualization => {
    this.report_visualization = report_visualization || []
  }

  getLanguageByCode = languageCode => this.languages.get(languageCode)

  getLanguageIcon = languageCode => this.languages.getIcon(languageCode)

  getLanguageLabel = languageCode => this.languages.getLabel(languageCode)

  getTextDirection = languageCode =>
    this.getLanguageByCode(languageCode).right_to_left ? 'rtl' : 'ltr'

  @action getReportVisualization = async () =>
    api
      .get('/report-visualizations/')
      .then(response => this.setReportVisualization(response.data))
      .catch(error => {
        this.error = error
      })

  @action getAudiences = async () =>
    api
      .get('/audiences/')
      .then(response => {
        this.audiences = response.data
      })
      .catch(error => error)

  @action getRelationships = async (params = {}) =>
    api
      .get('/relationships/', { params })
      .then(response => this.setRelationships(response.data))
      .catch(error => error)

  @computed get hasDefaultLinks() {
    const list = sessionStorage.getItem('knowledge-base-default-links')
    const listParsed = list ? JSON.parse(list) : []
    return listParsed && Array.isArray(listParsed) && listParsed.length > 0
  }

  @action setHelpList(list) {
    this.helpList = list
    this.setHelpListOnStorage(list)
  }

  @action setHelpListOnStorage(list) {
    const listParsed = JSON.stringify(list)
    sessionStorage.setItem('knowledge-base-default-links', listParsed)
  }

  @action copyHelpListFromStorage() {
    const list = sessionStorage.getItem('knowledge-base-default-links')
    this.helpList = JSON.parse(list)
  }

  @action getHelpList = async () => {
    if (this.hasDefaultLinks) {
      this.copyHelpListFromStorage()
    } else {
      await apiOld
        .get('/config/knowledge-base/')
        .then(response => this.setHelpList(response.data))
        .catch(() =>
          showNotification(
            "We've encountered a network or system issue. Please try again later.",
            'negative'
          )
        )
    }
  }

  @action getConfig() {
    return authApi
      .get('/appconfig/')
      .then(response => {
        this.organization = response.data.org
      })
      .catch(error => {
        this.error = error.message
      })
  }

  @action setWindowWidth(width) {
    this.windowWidth = width
  }
}
