import { computed, observable, makeObservable, action } from 'mobx'
import { competenciesApi } from '@api'
import Columns from '@stores/models/Columns'
import SuggestionsManager from '@stores/models/SuggestionsManager'
import Pagination from '@stores/models/Pagination'
import Competency from './Competency'

export default class Competencies {
  @observable list = []
  @observable pagination = new Pagination()
  @observable statuses = []
  @observable status_selected = 'all'
  @observable total = 0
  @observable selected = []
  @observable search = ''
  @observable columns = new Columns([{ name: 'Competencies', field_name: 'text' }])
  @observable error = null
  @observable retrieving = true
  @observable removing = false
  @observable suggestionsManager = new SuggestionsManager({
    api: competenciesApi,
    endpoint: '/suggest/',
    responseParser: ({ competencies }) => [
      {
        id: 'competencies',
        items: competencies.map(competency => ({
          id: competency,
          name: competency
        }))
      }
    ]
  })

  constructor(questionStore) {
    this.questionStore = questionStore
    makeObservable(this)
  }

  @action setTotal() {
    this.total = this.pagination.total
  }

  @action getList() {
    this.retrieving = true

    return competenciesApi
      .get('/', {
        params: {
          filter: this.status_selected,
          search: this.search,
          page: this.pagination.page
        }
      })
      .then(response => response.data)
      .catch(error => {
        this.error = error
      })
      .finally(() => {
        this.retrieving = false
      })
  }

  @action async get() {
    this.retrieving = true
    const response = await this.getList()
    if (response) {
      this.list = response.results.map(item => new Competency(this, item))
      this.pagination.setValues({
        total_pages: Math.ceil(response.count / 10),
        total: response.count,
        results_per_page: 10
      })
    }
  }

  @action async nextPage() {
    if (this.pagination.hasMorePages) {
      this.pagination.nextPage()
      const response = await this.getList()
      if (response) {
        this.list = [
          ...this.list,
          ...response.results.map(item => new Competency(this, item))
        ]
      }
    }
  }

  @action getStatus() {
    competenciesApi
      .get('/filters/', { params: { search: this.search } })
      .then(response => {
        this.statuses = response.data
      })
      .catch(error => {
        this.error = error
      })
  }

  @action getSelected() {
    return competenciesApi
      .get(`/question/${this.questionStore.id}/`)
      .then(response => {
        this.selected = response.data.competencies.map(value => ({ value }))
      })
      .catch(error => {
        this.error = error
      })
  }

  @action async addItems() {
    this.questionStore.items.forEach(({ id, value }) => {
      if (!this.selected.some(selected => selected.value === value)) {
        this.questionStore.removeItem(id)
      }
    })

    this.selected.forEach(item => {
      if (!this.questionStore.items.some(({ value }) => value === item.value)) {
        this.questionStore.addItem({ ...item, is_saved: true })
      }
    })

    this.questionStore.addingItem = false
    await this.questionStore.save()
    this.error = this.questionStore.error
  }

  @action add({ text }) {
    return competenciesApi
      .post(`/`, { text, competency_type: this.questionStore.type })
      .then(response => {
        this.error = null
        return response.data
      })
      .catch(error => {
        this.error = error
      })
  }

  @action edit(codename, params) {
    return competenciesApi.put(`/${codename}/`, params).catch(error => {
      this.error = error
    })
  }

  @action delete(codename) {
    this.removing = true

    return competenciesApi
      .delete(`/${codename}/`)
      .then(() => {
        this.getStatus()
        this.list = this.list.filter(item => item.codename !== codename)
        this.selected = this.selected.filter(item => item.value !== codename)
      })
      .catch(error => {
        this.error = error
      })
      .finally(() => {
        this.removing = false
      })
  }

  @action reset() {
    this.list = []
    this.pagination.reset()
    this.statuses = []
    this.status_selected = 'all'
    this.selected = []
    this.search = ''
    this.error = null
    this.suggestionsManager.reset()
  }

  @action setStatusSelected(status) {
    this.pagination.reset()
    this.status_selected = status
    this.suggestionsManager.setExternalParams({ filter: this.status_selected })
    return this.get()
  }

  @action setSearch(text) {
    this.search = text
    this.pagination.reset()
    this.getStatus()
    return this.get()
  }

  @action setSelected({ id, checked }) {
    if (checked) {
      const item = this.list.find(({ codename }) => codename === id)
      this.selected = [...this.selected, { ...item, value: id, is_new: true }]
    } else {
      this.selected = this.selected.filter(({ value }) => value !== id)
    }
  }

  @action clearSelection() {
    this.selected = []
  }

  @computed get saving() {
    return this.questionStore.saving
  }

  @computed get newSelectedCount() {
    return this.selected.filter(({ is_new }) => !!is_new).length
  }

  @computed get addButtonLabel() {
    const count = this.newSelectedCount

    if (count) return `Add (${count}) Competencies`

    return `Add Competencies`
  }

  @computed get statusesList() {
    return this.statuses.map(({ name, codename, total }) => ({
      id: codename,
      label: `${name} (${total})`,
      disabled: total === 0
    }))
  }
}
