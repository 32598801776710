import { QUESTIONS_TYPES } from '@constants'

import Question from './index'
import MultipleChoice from './MultipleChoice'
import Rating from './Rating'
import MultipleRating from './MultipleRating'
import MultipleSelection from './MultipleSelection'
import Counter from './Counter'

export const QUESTIONS_MODELS = {
  [QUESTIONS_TYPES.MULTIPLE_CHOICE]: MultipleChoice,
  [QUESTIONS_TYPES.RATING]: Rating,
  [QUESTIONS_TYPES.MULTIPLE_RATING]: MultipleRating,
  [QUESTIONS_TYPES.COUNTER]: Counter,
  [QUESTIONS_TYPES.MULTIPLE_SELECTION]: MultipleSelection,
  [QUESTIONS_TYPES.COMMENT]: Question(),
  [QUESTIONS_TYPES.OPEN_TEXT]: Question(),
  [QUESTIONS_TYPES.NPS]: Rating
}
