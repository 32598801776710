import { action, computed } from 'mobx'
import Question from '../index'
import { parseLegends, parseRates } from '../utils'

export default class MultipleRating extends Question {
  @computed get isCondensed() {
    return this.options.isCondensed
  }

  @computed get rateValues() {
    let rateValues = this.rate_values.map(({ value, text }) => ({
      value: value,
      text
    }))

    if (this.splitted) {
      rateValues = parseRates(rateValues, this.splitted)
    }

    return rateValues
  }

  @computed get columns() {
    return this.options.columns
  }

  @computed get dontKnowText() {
    return this.options.raw.dont_know_text
  }

  @computed get items() {
    return this.options.rows
  }

  @computed get desktopItems() {
    return this.desktop_options.rows
  }

  @action setItems(rows) {
    this.desktop_options.setRows(rows)
  }

  @computed get rate_values() {
    return parseLegends(this.options.columns, this.isCondensed)
  }

  @computed get splitted() {
    return this.options.raw.columns_by_row
  }

  @computed get legends() {
    if (!this.isCondensed) return null
    return this.rate_values.filter(rate => rate.legend).map(({ legend }) => legend)
  }

  @computed get dontKnowButton() {
    return this.dontKnowActive ? this.dontKnowOption : null
  }
}
