import { observable, makeObservable, action } from 'mobx'
import { api } from '@api'
import stores from '../'

export default class Editor {
  @observable codename = ''
  @observable error = null

  constructor(rootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @action setCodename = (codename = {}) => {
    this.codename = codename
  }

  @action setLanguages = languages => {
    stores.appState.setLanguages(languages)
  }

  @action getLanguages = () => {
    api
      .get('/organizations/languages/', { params: { is_active: true } })
      .then(response => this.setLanguages(response.data))
      .catch(error => {
        this.error = error
      })
  }
}
